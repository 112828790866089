import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export type UseConfirmLeavingPageProps = {
  isDirty?: boolean;
};

export const useConfirmLeavingPage = (isDirty?: boolean) => {
  useEffect(() => {
    window.onbeforeunload = function (event) {
      if (isDirty) {
        const message = "Sure you want to leave?";
        if (typeof event == "undefined") {
          event = window.event;
        }
        if (event) {
          event.returnValue = message;
        }
        return message;
      }
      return undefined;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  return null;
};
