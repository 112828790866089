import React, { memo } from "react";
import { useT } from "@/hooks/useT";
import { useQ } from "@/hooks/useQ";
import { portfolio } from "@/queries";
import { uris } from "@/config/nav";

import { ToggleMenu } from "./ToggleMenu";
import { LinkItem } from "./DrawerNavigation";

export const PortfolioNavigation = memo(() => {
  const { t } = useT();
  const { data = [] } = useQ(`portfolios`, () => portfolio.list());

  const items: LinkItem[] = data.map(({ name, id }) => ({
    id: `portfolio-${id}`,
    label: name,
    to: uris.portfolio.single,
    params: { id },
    end: true,
  }));

  return <ToggleMenu text={t("navigation.portfolios.title")} items={items} />;
});
