import React, { useCallback, useState, Fragment } from "react";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import * as Yup from "yup";
import { useFormik } from "formik";
import { prepareFormValues } from "../../../util/form";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../../../base/form/mui/FormSelect";
import { CustomDialog } from "@/layout/CustomDialog";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";

const NewItemForm = ({
  item,
  onSave,
  onClose,
  open,
  options,
  label,
  placeholder,
  ...props
}) => {
  const schema = yup.object().shape({
    itemId: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      itemId: item?.itemId || "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formData;

  useConfirmLeavingPage(isDirty);

  return (
    <CustomDialog isOpen={open} onClose={onClose}>
      <FormProvider {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave(data);
            reset();
          })}
        >
          <Box sx={{ width: "100%" }}>
            <FormSelect
              displayEmpty
              name={"itemId"}
              label={label}
              placeholder={placeholder}
              sx={{ maxWidth: "300px" }}
              fullWidth
              options={options}
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};

export const MultipleSelectSection = ({
  title,
  description,
  label,
  placeholder,
  items,
  options,
  onNewItemSubmit,
}) => {
  const { t } = useT();
  const [showNewItemForm, setShowNewItemForm] = useState(false);

  const newItemSchema = Yup.object().shape({
    itemId: Yup.string().required(t("projectForm.fields.name.requiredError")),
  });

  const newItemForm = useFormik({
    validationSchema: newItemSchema,
    initialValues: {
      itemId: "",
    },
    onSubmit: (v) => {
      const data = prepareFormValues(v);
      onNewItemSubmit(data);
      newItemForm.resetForm();
      setShowNewItemForm(false);
    },
  });

  const handleShowNewItemForm = useCallback(() => {
    setShowNewItemForm(true);
  }, []);

  return (
    <Section title={title}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography>{description}</Typography>
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          onClick={
            showNewItemForm
              ? () => setShowNewItemForm(false)
              : handleShowNewItemForm
          }
        >
          {showNewItemForm ? "Cancel" : "Add new"}
        </Button>
      </Stack>
      <CustomAccordion
        tableView
        header={{
          expanded: false,
          columns: [
            {
              title: "Title",
            },
          ],
          details: (
            <NewItemForm
              label={label}
              placeholder={placeholder}
              open={showNewItemForm}
              options={options}
              onClose={() => setShowNewItemForm(false)}
              onSave={(data) => {
                onNewItemSubmit(data);
                newItemForm.resetForm();
                setShowNewItemForm(false);
              }}
            />
          ),
        }}
        rows={items.map((item) => ({
          id: item,
          expanded: false,
          expandDisabled: true,
          columns: [
            {
              title: options.find((option) => option.value === item)?.label,
            },
          ],
        }))}
      />
    </Section>
  );
};
