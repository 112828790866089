import { PDFKit, styles } from "@/base/pdf";
import { path, pathOr } from "ramda";
import {
  issueMatrix,
  opportunityMatrix,
  threatMatrix,
  top5OpenIssues,
  top5OpenRisks,
} from "@/base/risk/Risk";
import { Document, Page as PDFPage, View } from "@react-pdf/renderer";
import moment from "moment/moment";
import { DatePretty } from "@/base/DatePretty";
import redIcon from "@/assets/images/red_icon.png";
import greenIcon from "@/assets/images/green_icon.png";
import yellowIcon from "@/assets/images/yellow_icon.png";
import emptyIcon from "@/assets/images/empty_icon.png";
import { priorityColor } from "@/util/priority";
import React from "react";

export const HighlightReportPdfDocument = ({
  t,
  project,
  report,
  type,
  stages,
}) => {
  const { H1, H2, H3, H4, BODY, CAPTION, HR, HTML, BlockChartPdf } = PDFKit;

  const statusData = [
    "benefits",
    "time",
    "cost",
    "quality",
    "scope",
    "risk",
  ].map((name) => {
    return {
      aspect: t("project.progress.highlightReport.fields." + name + ".title"),
      status: path([name, "status"], report),
      description: path([name, "content"], report),
    };
  });

  const issues = top5OpenIssues(pathOr([], ["change", "items"], report));
  const risks = top5OpenRisks(pathOr([], ["risk", "items"], report));
  const hasOpportunities =
    risks.filter((item) => item.type === "opportunity").length > 0;

  const stageName =
    report?.stage?.id === project?.stage?.id
      ? project?.stage?.name
      : stages &&
        stages.filter((stage) => stage?.id === report?.stage?.id)[0]?.name;

  return (
    <Document
      title={`highlightReport_${project.name.replace(/ /g, "")}_${moment(
        report?.to || report?.date,
      ).format("YYYY-MM-DD")}`}
    >
      <PDFPage size="A4" style={styles.page}>
        <H1>
          {t(
            "project.progress." +
              (type !== undefined ? type : "highlightReport") +
              ".title",
          )}
          {type === "endStage" && stageName ? " - " + stageName : ""}
        </H1>
        <H2>
          {project.name} -{" "}
          <DatePretty format={"YYYY-MM-DD"} date={report?.to || report?.date} />
        </H2>
        {!!(project?.projectManager ?? report?.manager?.name) && (
          <CAPTION style={{ textAlign: "center", marginTop: 10 }}>
            Managed by: {project?.projectManager ?? report?.manager?.name}
          </CAPTION>
        )}
        <View style={styles.section}>
          <BODY>
            <H3>
              {t("project.progress.highlightReport.fields.summary.title")}
            </H3>
            <HTML>
              {`
            ${
              path(["value"], report) === "red"
                ? `<img src="${redIcon}" style="width: 14px; height: 14px; display: inline"/>`
                : ""
            }
            ${
              path(["value"], report) === "green"
                ? `<img src="${greenIcon}" style="width: 14px; height: 14px; display: inline"/>`
                : ""
            }
            ${
              path(["value"], report) === "yellow"
                ? `<img src="${yellowIcon}" style="width: 14px; height: 14px; display: inline"/>`
                : ""
            }
            ${
              path(["value"], report) === null
                ? `<img src="${emptyIcon}" style="width: 14px; height: 14px; display: inline"/>`
                : ""
            }`}
            </HTML>
          </BODY>
          <HR />
          {report?.summary && <HTML>{report?.summary}</HTML>}
        </View>
        <View style={styles.section}>
          <H3>
            {t(
              "project.progress.highlightReport.fields.performedAchievements.title",
            )}
          </H3>
          <HR />
          <BODY>
            ({t("project.progress.report.from")}{" "}
            <DatePretty format={"YYYY-MM-DD"} date={report?.from} />)
          </BODY>
          {report?.performedAchivements && (
            <HTML>{report?.performedAchivements}</HTML>
          )}
        </View>
        <View style={styles.section}>
          <H3>
            {t(
              "project.progress.highlightReport.fields.plannedAchievements.title",
            )}
          </H3>
          <HR />
          {report?.plannedAchivements && (
            <HTML>{report?.plannedAchivements}</HTML>
          )}
          <HTML>{`<table>
              <thead>
                <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                  <th style="padding: 16px 8px; max-width: 80px; text-transform: uppercase; font-size: 10px; color: #6b7280">Aspect</th>
                  <th style="padding: 16px 8px; max-width: 70px; text-transform: uppercase; font-size: 10px; color: #6b7280">Status</th>
                  <th style="padding: 16px 8px; text-transform: uppercase; font-size: 10px; color: #6b7280">Description</th>
                </tr>
              </thead>
              <tbody>
                ${statusData
                  .map(
                    (row) => `<tr>
                  <td style="padding: 16px 8px; max-width: 80px; border: 1px solid #e5e7db; border-top: none;">${
                    row.aspect
                  }</td>   
                  <td style="padding: 16px 8px; max-width: 70px; border: 1px solid #e5e7db; border-top: none; text-align: center">
                    ${
                      row.status === "red"
                        ? `<img src="${redIcon}" style="width: 14px; height: 14px; display: inline"/>`
                        : ""
                    }
                    ${
                      row.status === "green"
                        ? `<img src="${greenIcon}" style="width: 14px; height: 14px; display: inline"/>`
                        : ""
                    }
                    ${
                      row.status === "yellow"
                        ? `<img src="${yellowIcon}" style="width: 14px; height: 14px; display: inline"/>`
                        : ""
                    }
                    ${
                      row.status === null
                        ? `<img src="${emptyIcon}" style="width: 14px; height: 14px; display: inline"/>`
                        : ""
                    }
                  </td>
                  <td style="padding: 16px 8px; border: 1px solid #e5e7db; border-top: none;">${
                    row.description ?? ""
                  }</td>   
                </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`}</HTML>
        </View>
        {!!risks.length && (
          <View style={styles.section}>
            <H3>{t("project.progress.highlightReport.fields.risk.title")}</H3>
            <HR />
            <BlockChartPdf
              title={t("project.risksAndIssues.threats")}
              data={threatMatrix(risks)}
              yLabel={t("project.risksAndIssues.probability")}
              xLabel={t("project.risksAndIssues.impact")}
            />
            {hasOpportunities ? (
              <BlockChartPdf
                title={t("project.risksAndIssues.opportunities")}
                data={opportunityMatrix(risks)}
                yLabel={t("project.risksAndIssues.probability")}
                xLabel={t("project.risksAndIssues.impact")}
              />
            ) : null}
            <HTML>{`
                ${risks
                  .map(
                    (item) => `<table>
                        <tbody style="padding: 16px 8px; border: 1px solid #e5e7db;">
                          <tr>
                              <td>
                                <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;">
                                  <div style="width: 15px; height: 15px; background-color: ${priorityColor(
                                    item,
                                  )}; border-radius: 50%; margin-right: 5px;"></div><span>ID: ${
                                    item.no
                                  }</span> <span><strong>${
                                    item.type.toLowerCase() === "opportunity"
                                      ? t("risk.type.opportunity") + ": "
                                      : ""
                                  }${item.title}
                                </strong></span>
                                </div>
                                <br /><br /><strong>${t(
                                  "risk.registerForm.fields.riskStatement",
                                )}:</strong><br/>${`${t(
                                  "risk.registerForm.formHelpText.1",
                                )}${item.cause ? item.cause : ""}${t(
                                  "risk.registerForm.formHelpText.2",
                                )}${
                                  item.type
                                    ? t("risk.type." + item.type?.toLowerCase())
                                    : "threat"
                                }${t("risk.registerForm.formHelpText.3")}${
                                  item.event ? item.event : ""
                                }${t("risk.registerForm.formHelpText.4")}${
                                  item.effect ? item.effect : ""
                                }${t("risk.registerForm.formHelpText.5")}`}
                                ${
                                  item.action
                                    ? `<br /><br /><strong>${t(
                                        "risk.registerForm.fields.action.title",
                                      )}:</strong><br/>${item.action ?? ""}`
                                    : ""
                                }
                                ${
                                  item.owner || item.actionee
                                    ? `<br /><strong>${t(
                                        "risk.registerForm.fields.actionee.header",
                                      )}:</strong><br/>${
                                        item.owner?.length ? item.owner : "-"
                                      } / ${
                                        item.actionee?.length
                                          ? item.actionee
                                          : "-"
                                      }`
                                    : ""
                                }
                                ${
                                  item.comments
                                    ? `<br /><br /><strong>${t(
                                        "risk.registerForm.fields.comments.title",
                                      )}:</strong><br/>${item.comments ?? ""}`
                                    : ""
                                }
                              </td>   
                          </tr>
                        </tbody>
                      </table>`,
                  )
                  .join("")}`}</HTML>
          </View>
        )}
        {!!issues.length && (
          <View style={styles.section}>
            <H3>{t("project.risksAndIssues.issues")}</H3>
            <HR />
            <BlockChartPdf
              title={t("project.risksAndIssues.issues")}
              data={issueMatrix(issues)}
              yLabel={t("project.risksAndIssues.priority")}
              xLabel={t("project.risksAndIssues.severity")}
            />
            <HTML>{`
                ${issues
                  .map(
                    (item) => `<table>
                        <tbody style="padding: 16px 8px; border: 1px solid #e5e7db;">
                          <tr>
                              <td>
                                <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;">
                                  <div style="width: 15px; height: 15px; background-color: ${priorityColor(
                                    item,
                                  )}; border-radius: 50%; margin-right: 5px;"></div><span>ID: ${
                                    item.no
                                  }</span> <span><strong>${
                                    item.type.toLowerCase() === "opportunity"
                                      ? t("risk.type.opportunity") + ": "
                                      : ""
                                  }${item.title}
                                </strong></span>
                                </div>
                                ${
                                  item.description
                                    ? `<br /><br /><strong>Description:</strong><br/>${
                                        item.description ?? ""
                                      }`
                                    : ""
                                }
                                ${
                                  item.impact
                                    ? `<br /><br /><strong>Impact:</strong><br/>${
                                        item.impact ?? ""
                                      }`
                                    : ""
                                }
                                ${
                                  item.recommendation
                                    ? `<br /><br /><strong>Recommendation:</strong><br/>${item.recommendation}`
                                    : ""
                                }
                                ${
                                  item.decision
                                    ? `<br /><br /><strong>Decision:</strong><br/>${item.decision}`
                                    : ""
                                }
                                ${
                                  item.status
                                    ? `<br /><br /><strong>Status:</strong><br/>${item.status}`
                                    : ""
                                }
                              </td>   
                          </tr>
                        </tbody>
                      </table>`,
                  )
                  .join("")}`}</HTML>
          </View>
        )}
      </PDFPage>
    </Document>
  );
};
