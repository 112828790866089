import React, { useContext, useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { uris } from "@/config/nav";
import { Home } from "@/pages/home/Home";
import { E404 } from "@/pages/errors/E404";
import { NewProject } from "@/pages/projects/NewProject";
import { Project } from "@/pages/projects/Project";
import { AuthenticationContext } from "@/auth/AuthenticationProvider";
import { E401 } from "@/pages/errors/E401";
import { Login } from "@/pages/login/Login";
import { Signup } from "@/pages/signup/Signup";
import { LinkLoginLander } from "@/pages/login/LinkLoginLander";
import { Responsive } from "@/pages/dev/Responsive";
import { Quality } from "@/pages/projects/Quality";
import { Details } from "@/pages/portfolio/Details";
import { Progress } from "@/pages/projects/Progress";
import { E500 } from "@/pages/errors/E500";
import { Plans } from "@/pages/projects/Plans";
import { PlansApproach } from "@/pages/projects/plans/Approach";
import { BusinessCase } from "@/pages/projects/BusinessCase";
import { Change } from "@/pages/projects/Change";
import { Organization } from "@/pages/projects/Organization";
import { ProgressEditForm } from "@/pages/projects/ProgressEditForm";
import { ProgressCreateForm } from "@/pages/projects/ProgressCreateForm";
import { users } from "@/queries";
import { EditLinks } from "@/pages/projects/EditLinks";
import { UserAdministration } from "@/pages/home/AccountSettings/UserAdministration";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { Properties } from "@/pages/home/AccountSettings/Properties/Properties";
import { ProjectSettings as TenantProjectSettings } from "@/pages/home/AccountSettings/ProjectSettings/ProjectSettings";
import { Portfolios } from "@/pages/home/AccountSettings/Portfolios/Portfolios";
import { Lifecycles } from "@/pages/home/AccountSettings/Lifecycles/Lifecycles";
import { Tailoring } from "@/pages/home/AccountSettings/Tailoring/Tailoring";
import { DataProtectionAgreement } from "@/pages/home/AccountSettings/DataProtectionAgreement";
import { Product } from "@/pages/products/Product";
import { ProjectProductDescription } from "@/pages/projects/ProjectProductDescription";
import { TypeSelectPage } from "@/pages/home/TypeSelect";
import { Lessons } from "@/pages/projects/Lessons";
import { BriefPdf } from "@/pages/projects/ProjectBriefAndInitiationDoc/PDF/BriefPdf";
import { CombinedProjectReports } from "@/pages/projects/ProjectBriefAndInitiationDoc/ProjectBriefAndInitiationDoc";
import { PlansControls } from "@/pages/projects/plans/Controls";
import { OrganizationApproach } from "@/pages/projects/organization/OrganizationApproach";
import { ProjectRiskApproach } from "@/pages/projects/risk/RiskApproach";
import { ChangeApproach } from "@/pages/projects/change/ChangeApproach";
import { Programme } from "@/pages/programmes/Programme";
import { ProgrammeDefinitionForm } from "@/pages/programmes/ProgrammeDefinitionForm";
import { PIDPdf } from "@/pages/projects/ProjectBriefAndInitiationDoc/PDF/PIDPdf";
import { Release } from "@/pages/products/releases";
import { EditProperties } from "@/pages/projects/EditProperties";
import { NewProgramme } from "@/pages/programmes/NewProgramme";
import { NewProduct } from "@/pages/products/NewProduct";
import { Knowledge } from "@/pages/programmes/Knowledge/Knowledge";
import { KnowledgeCreateForm } from "@/pages/programmes/Knowledge/KnowledgeCreateForm";
import { KnowledgeEditForm } from "@/pages/programmes/Knowledge/KnowledgeEditForm";
import { Decisions } from "@/pages/programmes/Decisions";
import { MyProfile } from "@/pages/home/MyProfile";
import { ProgrammeRisk } from "@/pages/programmes/ProgrammeRisk";
import { ProgrammeRiskApproach } from "@/pages/programmes/ProgrammeRiskApproach";
import { ReleaseRisk } from "@/pages/products/releases/ReleaseRisk";
import { Risk } from "@/pages/projects/Risk";
import { ReleasePlan } from "@/pages/products/releases/ReleasePlan";
import { ReleaseChange } from "@/pages/products/releases/Change";
import { ReleaseProgressCreateForm } from "@/pages/products/releases/progress/ReleaseProgressCreateForm";
import { ReleaseProgressEditForm } from "@/pages/products/releases/progress/ReleaseProgressEditForm";
import { ReleaseProgress } from "@/pages/products/releases/progress/ReleaseProgress";
import { ProductProgress } from "@/pages/products/ProductProgress";
import { ProductProgressCreateForm } from "@/pages/products/ProductProgressCreateForm";
import { ProductDefinitionForm } from "@/pages/products/ProductDefinitionForm";
import { SearchResults } from "@/pages/home/SearchResults";
import { StakeholderAnalysis } from "@/pages/projects/organization/StakeholderAnalysis";
import { ProductRisk } from "@/pages/products/ProductRisk";
import { ProductChange } from "@/pages/products/Change";
import { EventLog } from "@/pages/projects/EventLog";
import { LessonsArchive } from "@/pages/home/LessonsArchive";
import { ProjectSettings } from "@/pages/projects/ProjectSettings";
import { EditProgrammeProperties } from "@/pages/programmes/EditProgrammeProperties";
import { Design } from "@/pages/programmes/Design";
import { EditProductProperties } from "@/pages/products/EditProductProperties";

export const AuthRoute = ({ ...props }) => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const updateContext = useUnleashContext();
  useEffect(() => {
    if (!auth.authenticated) {
      users
        .me()
        .then((result) => {
          console.log("[INFO] Retrieved user information");
          dispatch({ type: "LOGIN", payload: result });
          updateContext({
            userId: result.id,
            hostName: window.location.hostname,
            account: result.currentTenant,
          });
        })
        .catch((error) => {
          console.log(
            "[WARN] Not able to login user, change to 401 error page",
          );
          navigate(uris.e401);
        });
      //    return null;
    } else {
      updateContext({
        userId: auth.user.id,
        hostName: window.location.hostname,
        account: auth.user.currentTenant,
      });
    }
    //    updateContext({ userId })
  }, []);
  return auth.authenticated ? <Outlet /> : navigate(uris.login);
};

export const AppRouterProvider = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthRoute />,
      children: [
        { path: uris.home, element: <Home /> },
        { path: uris.myProfile, element: <MyProfile /> },
        { path: uris.lessons, element: <LessonsArchive /> },
        { path: uris.searchResults, element: <SearchResults /> },
        {
          path: uris.project.newWithPortfolioIdAndProgrammeId,
          element: <NewProject />,
        },
        { path: uris.new, element: <TypeSelectPage /> },
        { path: uris.project.new, element: <NewProject /> },
        {
          path: uris.project.newWithPortfolioId,
          element: <NewProject />,
        },
        { path: uris.programme.new, element: <NewProgramme /> },
        {
          path: uris.programme.newWithPortfolioId,
          element: <NewProgramme />,
        },
        { path: uris.programme.log, element: <EventLog /> },
        { path: uris.programme.risk, element: <ProgrammeRisk /> },
        {
          path: uris.programme.riskDetails,
          element: <ProgrammeRisk />,
        },
        {
          path: uris.programme.riskApproach,
          element: <ProgrammeRiskApproach />,
        },
        { path: uris.project.single, element: <Project /> },
        {
          path: uris.project.editProperties,
          element: <EditProperties />,
        },
        { path: uris.project.quality, element: <Quality /> },
        {
          path: uris.project.qualityOutputDetails,
          element: <Quality />,
        },
        {
          path: `${uris.project.quality}/productDescription`,
          element: <ProjectProductDescription />,
        },
        {
          path: `${uris.project.quality}/approach`,
          element: <Quality />,
        },
        { path: uris.project.plans, element: <Plans /> },
        {
          path: uris.project.plansStageDetails,
          element: <Plans />,
        },
        { path: uris.project.plansLessons, element: <Lessons /> },
        {
          path: uris.project.plansApproach,
          element: <PlansApproach />,
        },
        {
          path: uris.project.plansControls,
          element: <PlansControls />,
        },
        {
          path: uris.project.businessCase,
          element: <BusinessCase />,
        },
        { path: uris.project.risk, element: <Risk /> },
        { path: uris.project.riskDetails, element: <Risk /> },
        {
          path: uris.project.riskApproach,
          element: <ProjectRiskApproach />,
        },
        { path: uris.project.change, element: <Change /> },
        {
          path: uris.project.changeDetails,
          element: <Change />,
        },
        {
          path: uris.project.changeApproach,
          element: <ChangeApproach />,
        },
        {
          path: uris.project.organization,
          element: <Organization />,
        },
        {
          path: uris.project.organizationApproach,
          element: <OrganizationApproach />,
        },
        {
          path: uris.project.organizationStakeholderAnalysis,
          element: <StakeholderAnalysis />,
        },
        {
          path: uris.project.edit_progress,
          element: <ProgressEditForm />,
        },
        {
          path: uris.project.create_progress,
          element: <ProgressCreateForm />,
        },
        {
          path: uris.project.progressSingle,
          element: <Progress />,
        },
        {
          path: uris.project.progressType,
          element: <Progress />,
        },
        {
          path: uris.project.progressReportId,
          element: <Progress />,
        },
        {
          path: uris.project.progressReportIdPdf,
          element: <Progress />,
        },
        {
          path: uris.project.progressFull,
          element: <Progress />,
        },
        {
          path: uris.project.progressFullscreen,
          element: <Progress />,
        },
        { path: uris.portfolio.single, element: <Details /> },
        { path: uris.project.editLinks, element: <EditLinks /> },
        { path: uris.product.editLinks, element: <EditLinks /> },
        { path: uris.product.new, element: <NewProduct /> },
        { path: uris.product.roadmap, element: <Plans /> },
        {
          path: uris.product.roadmapReleaseDetails,
          element: <Plans />,
        },
        {
          path: uris.product.editProperties,
          element: <EditProductProperties />,
        },
        { path: uris.product.log, element: <EventLog /> },
        { path: uris.project.log, element: <EventLog /> },
        { path: uris.release.log, element: <EventLog /> },
        {
          path: uris.project.settings,
          element: <ProjectSettings />,
        },
        {
          path: uris.product.createProgress,
          element: <ProductProgressCreateForm />,
        },
        {
          path: uris.product.editProgress,
          element: <ProductProgressCreateForm />,
        },
        {
          path: uris.product.progressReportIdPdf,
          element: <ProductProgress />,
        },
        {
          path: uris.product.progressFull,
          element: <ProductProgress />,
        },
        {
          path: uris.product.editDefinition,
          element: <ProductDefinitionForm />,
        },
        { path: uris.product.risk, element: <ProductRisk /> },
        {
          path: uris.product.riskDetails,
          element: <ProductRisk />,
        },
        { path: uris.product.change, element: <ProductChange /> },
        {
          path: uris.product.changeDetails,
          element: <ProductChange />,
        },
        {
          path: uris.product.organization,
          element: <Organization />,
        },
        { path: uris.release.single, element: <Release /> },
        { path: uris.release.risk, element: <ReleaseRisk /> },
        {
          path: uris.release.riskDetails,
          element: <ReleaseRisk />,
        },
        { path: uris.release.plan, element: <ReleasePlan /> },
        { path: uris.release.change, element: <ReleaseChange /> },
        {
          path: uris.release.changeDetails,
          element: <ReleaseChange />,
        },
        {
          path: uris.release.createProgress,
          element: <ReleaseProgressCreateForm />,
        },
        {
          path: uris.release.editProgress,
          element: <ReleaseProgressEditForm />,
        },
        {
          path: uris.release.progressReportIdPdf,
          element: <ReleaseProgress />,
        },
        {
          path: uris.release.progressFull,
          element: <ReleaseProgress />,
        },
        {
          path: "/accountsettings/users",
          element: <UserAdministration />,
        },
        {
          path: "/accountsettings/properties",
          element: <Properties />,
        },
        {
          path: "/accountsettings/projectsettings",
          element: <TenantProjectSettings />,
        },
        {
          path: "/accountsettings/portfolios",
          element: <Portfolios />,
        },
        {
          path: "/accountsettings/lifecycles",
          element: <Lifecycles />,
        },
        {
          path: "/accountsettings/tailoring",
          element: <Tailoring />,
        },
        {
          path: "/accountsettings/dpa",
          element: <DataProtectionAgreement />,
        },
        { path: uris.project.briefPdf, element: <BriefPdf /> },
        {
          path: uris.project.briefPDFHistoric,
          element: <BriefPdf />,
        },
        {
          path: uris.project.brief,
          element: <CombinedProjectReports />,
        },
        {
          path: uris.project.briefHistoric,
          element: <CombinedProjectReports />,
        },
        {
          path: uris.project.approveBrief,
          element: <CombinedProjectReports />,
        },
        {
          path: uris.project.pid,
          element: <CombinedProjectReports />,
        },
        {
          path: uris.project.pidHistoric,
          element: <CombinedProjectReports />,
        },
        {
          path: uris.project.approvePid,
          element: <CombinedProjectReports />,
        },
        { path: uris.project.pidPDf, element: <PIDPdf /> },
        {
          path: uris.project.pidPDFHistoric,
          element: <PIDPdf />,
        },
        { path: uris.programme.single, element: <Programme /> },
        {
          path: uris.programme.createKnowledge,
          element: <KnowledgeCreateForm />,
        },
        {
          path: uris.programme.editKnowledge,
          element: <KnowledgeEditForm />,
        },
        {
          path: uris.programme.knowledgeReportIdPdf,
          element: <Knowledge />,
        },
        {
          path: uris.programme.knowledgeFull,
          element: <Knowledge />,
        },
        {
          path: uris.programme.editDefinition,
          element: <ProgrammeDefinitionForm />,
        },
        { path: uris.programme.change, element: <Decisions /> },
        {
          path: uris.programme.changeDetails,
          element: <Decisions />,
        },
        { path: uris.programme.design, element: <Design /> },
        {
          path: uris.programme.changeApproach,
          element: <ChangeApproach />,
        },
        {
          path: uris.programme.organization,
          element: <Organization />,
        },
        {
          path: uris.programme.editProperties,
          element: <EditProgrammeProperties />,
        },
      ],
    },
    { path: uris.product.single, element: <Product /> },
    { path: uris.login, element: <Login /> },
    { path: uris.signup, element: <Signup /> },
    { path: uris.loginCode, element: <LinkLoginLander /> },
    { path: "/dev/responsive", element: <Responsive /> },
    { path: uris.errors.e500, element: <E500 /> },
    { path: uris.errors.e401, element: <E401 /> },
    { path: uris.errors.e404, element: <E404 /> },
    { path: uris.errors.e404_fallback, element: <E404 /> },
  ]);

  return <RouterProvider router={router} />;
};
