import React, { useEffect, useState } from "react";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useQ } from "../../hooks/useQ";
import { currency, portfolio, product } from "../../queries";
import { useMutation } from "react-query";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { Hide } from "../../base/responsive/Hide";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import { Section } from "../../layout/Section";
import { Grid, Stack } from "@mui/material";
import { FormTextField } from "../../base/form/mui/FormTextField";
import { FormSelect } from "../../base/form/mui/FormSelect";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { PrimaryButton, SecondaryButton } from "../../base/Button";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";
import { BlockerAlert } from "@/base/BlockerAlert";

export const NewProduct = () => {
  const { t } = useT();
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: portfolios = [], isLoading } = useQ(`portfolios`, () =>
    portfolio.list(),
  );
  const {
    data: currencies = [],
    refetch: refetchCurrencies,
    isLoading: isLoadingCurrencies,
  } = useQ(`currencies`, () => currency.list());

  function getOptions(array) {
    return array.map((e) => {
      return { label: e.name, value: e.id };
    });
  }

  const addProduct = useMutation(product.add, {
    onSuccess: (data) => {
      navigate(clientUrl(uris.product.single, data.data));
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string().trim().required(),
    identifier: Yup.string().trim(),
    portfolio: Yup.string().trim().required(),
    currency: Yup.string().trim(),
    background: Yup.string().trim(),
    description: Yup.string().trim(),
    vision: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      identifier: "",
      portfolio: "",
      currency: "",
      background: "",
      description: "",
      vision: "",
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = formData;

  useEffect(() => {
    setValue(
      "portfolio",
      portfolioId ?? (portfolios.length !== 0 ? portfolios[0].id : ""),
    );
    setValue("currency", currencies.length !== 0 ? currencies[0] : "");
  }, [portfolioId, portfolios, currencies, setValue]);

  useConfirmLeavingPage(isDirty);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !!isDirty &&
      !isSubmitting,
  );

  return (
    <Page>
      <NoMobileView show={"sm"} />

      <Hide hide={"sm"}>
        <FormDataProvider formKey="product.addEditForm.fields" {...formData}>
          <BlockerAlert blocker={blocker} />
          <form
            onSubmit={handleSubmit((data) => {
              setIsSubmitting(true);
              addProduct.mutate(data);
            })}
          >
            <Section title={t("product.addProduct")}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTextField name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField name="identifier" />
                </Grid>

                {portfolios.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="portfolio"
                      options={getOptions(portfolios)}
                    />
                  </Grid>
                ) : null}

                {currencies.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="currency"
                      options={currencies.map((e) => {
                        return { label: e, value: e };
                      })}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Section>

            <Section title={t("projectForm.background.title")}>
              <Stack spacing={2}>
                <FormRichTextField name={"description"} />
                <FormRichTextField name={"background"} />
                <FormRichTextField name={"vision"} />
              </Stack>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <SecondaryButton
                  onClick={() => {
                    navigate(-2);
                  }}
                >
                  {t("generic.button.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  //disabled={!isEmpty(addProjectForm.errors)}
                  className={"mg-l-nm"}
                  type={"submit"}
                >
                  {t("projectForm.submit.create")}
                </PrimaryButton>
              </Stack>
            </Section>
          </form>
        </FormDataProvider>
      </Hide>
    </Page>
  );
};
