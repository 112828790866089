import React, { memo } from "react";
import { uris } from "@/config/nav";
import { useToast } from "@/hooks/useToast";
import { project } from "@/queries";
import moment from "moment";
import { isNil } from "ramda";
import { useMutation } from "react-query";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import i18n from "@/i18n";
import { useT } from "@/hooks/useT";
import { ToggleMenu } from "./ToggleMenu";

function getReportArchive({ isPid, projectData, projectId }, t) {
  const latestBrief = projectData?.briefHistory?.slice(-1)[0] || {};
  const latestPid = projectData?.history?.slice(-1)[0] || {};

  const briefDraftIsLatest = !moment(projectData?.brief?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  const briefReportIsLatest = !moment(latestBrief?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  const pidDraftIsLatest = !moment(projectData?.pid?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  const pidReportIsLatest = !moment(latestPid?.date).isBefore(
    projectData?.updated,
    "seconds",
  );

  const reportArchive =
    pidDraftIsLatest ||
    briefDraftIsLatest ||
    pidReportIsLatest ||
    briefReportIsLatest
      ? []
      : [
          {
            id: "current",
            label: t("project.navigation.documentArchive.current"),
            to: isPid ? uris.project.pid : uris.project.brief,
            params: {
              id: projectId,
            } as { id: string; version?: string },
            end: true,
          },
        ];

  const approvedReports = isPid
    ? projectData?.history?.map((item) => ({
        id: item.id,
        label: `${item.majorVersion}.${item.minorVersion} (${new Date(item.date)
          .toISOString()
          .slice(0, 10)})`,
        to: uris.project.pidHistoric,
        params: {
          id: projectId,
          version: `${item?.majorVersion}.${item?.minorVersion}`,
        },
        end: true,
      }))
    : projectData?.briefHistory?.map((item) => ({
        id: item.id,
        label: `1.${item.version} (${new Date(item.date)
          .toISOString()
          .slice(0, 10)})`,
        to: uris.project.briefHistoric,
        params: {
          id: projectId,
          version: `1.${item?.version}`,
        },
        end: true,
      }));

  if (!isNil(approvedReports)) reportArchive.push(...approvedReports.reverse());

  if (isPid && projectData?.pid?.type === "draft") {
    reportArchive.splice(pidDraftIsLatest || briefDraftIsLatest ? 0 : 1, 0, {
      id: "pidDraft",
      label: `${projectData?.pid?.majorVersion}.${projectData?.pid
        ?.minorVersion} draft (${new Date(projectData?.pid?.date)
        .toISOString()
        .slice(0, 10)})`,
      to: uris.project.pidHistoric,
      params: {
        id: projectId,
        version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
      },
      end: true,
    });
  }
  if (!isPid && projectData?.brief?.type === "draft") {
    reportArchive.splice(pidDraftIsLatest || briefDraftIsLatest ? 0 : 1, 0, {
      id: "briefDraft",
      label: `1.${projectData?.brief?.version} draft (${new Date(
        projectData?.brief?.date,
      )
        .toISOString()
        .slice(0, 10)})`,
      to: uris.project.briefHistoric,
      params: {
        id: projectId,
        version: `1.${projectData?.brief?.version}`,
      },
      end: true,
    });
  }
  return reportArchive;
}

export const PidBriefNavigation = memo(
  ({ projectData, refetch }: { projectData: any; refetch: () => any }) => {
    const loc = useLocation();
    const toast = useToast();
    const { t } = useT();
    const { id = "null", version } = useParams();
    const isPid = matchPath(
      {
        path: uris.project.pid + "/*",
      },
      loc.pathname,
    );

    const navigate = useNavigate();

    const createDraft = useMutation(
      isPid ? project.draftPID : project.draftBrief,
      {
        onSuccess: (data) => {
          console.log("Received data: " + JSON.stringify(data));
          toast.success("Report has been drafted");
          navigate(isPid ? `/projects/${id}/pid/` : `/projects/${id}/brief/`);
          window.scrollTo(0, 0);
          refetch();
        },
        onError: ({ error }) => {
          console.log("Received error: " + JSON.stringify(error));
          toast.error("Error creating draft");
        },
      },
    );
    return (
      <>
        <ToggleMenu
          data-testid="PidBriefNav"
          text={t("project.navigation.reportActions.view")}
          items={[
            {
              id: "pdf",
              label: t("navigation.projects.progress.view.pdf"),
              to: version
                ? isPid
                  ? uris.project.pidPDFHistoric
                  : uris.project.briefPDFHistoric
                : isPid
                  ? uris.project.pidPDf
                  : uris.project.briefPdf,
              params: {
                id,
                version: version,
              },
            },
            {
              id: "word",
              label: t("navigation.projects.progress.view.word"),
              to: `/external/projects/${id}/${
                isPid ? "pid" : "brief"
              }/?version=0&fullPage=true&docx=true&lang=${
                i18n.language === "no" ? "norwegian" : "english"
              }`,
              params: {},
              end: true,
              externalUrl: true,
            },
          ]}
        />
        <ToggleMenu
          text={t("project.navigation.reportActions.title")}
          items={[
            {
              id: "createDraft",
              label: t("project.navigation.reportActions.createDraft"),
              to: "",
              params: { id },
              onClick: () =>
                createDraft.mutate({
                  id,
                  data: undefined,
                }),
              end: true,
            },
            {
              id: "approve",
              label: t("project.navigation.reportActions.approve"),
              to: isPid ? uris.project.approvePid : uris.project.approveBrief,
              params: { id },
              end: true,
            },
          ]}
        />
        <ToggleMenu
          text={t("project.navigation.documentArchive.title")}
          startsOpen={true}
          items={getReportArchive({ isPid, projectData, projectId: id }, t)}
        />
      </>
    );
  },
);
