import React from "react";
import { useParams, useMatch } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { product } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { CheckpointReport } from "../projects/components/CheckpointReport";
import { HighlightReport } from "../projects/components/HighlightReport";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import { HighlightReportPdf } from "../projects/HighlightReportPdf";
import { isEmpty, isNil, path } from "ramda";

export const ProductProgress = () => {
  const { id = "", reportId = "", type } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`product-${id}`, () =>
    product.single({ id }),
  );
  const { data: reportData = [] } = useQ(
    `product-${id}-report-highlights`,
    () => product.report.highlights({ id }),
  );

  const report = reportData
    .filter(
      (item, index) =>
        item.id === reportId ||
        (type === "highlight" && isNil(reportId)) ||
        (type === "highlight" && reportId === "latest") ||
        (isEmpty(reportId) && index === 0 && type === "highlight"),
    )
    .map((item) => ({
      ...item,
      summary: path(["summary", "content"], item),
      value: path(["summary", "status"], item),
    }))[0];

  const { title } = data;

  console.log({ id, reportId, type });

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.progress.title")}>
        {useMatch(
          clientUrl(uris.product.progressFull, {
            id,
            type: "highlight",
            reportId,
          }),
        ) && (
          <HighlightReport
            initiative={data}
            report={report}
            initiativeType="product"
          />
        )}
        {useMatch(
          clientUrl(uris.product.progressFull, {
            id,
            type: "endStage",
            reportId,
          }),
        ) && <HighlightReport initiative={data} report={report} />}
        {useMatch(
          clientUrl(uris.product.progressFull, {
            id,
            type: "endProject",
            reportId,
          }),
        ) && <HighlightReport initiative={data} report={report} />}
        {useMatch(
          clientUrl(uris.product.progressReportIdPdf, {
            id,
            reportId,
          }),
        ) && (
          <HighlightReportPdf
            productId={id}
            reportId={reportId}
            reportType="product"
          />
        )}
      </Section>
    </Page>
  );
};
