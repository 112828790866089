import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { PageSearch } from "./PageSearch";
import { useFlag } from "../hooks/useFlag";
import { ProfileMenu } from "./ProfileMenu";
import { LeftNav } from "./LeftNav";
import { AppBreadcrumbs } from "./Breadcrumbs";
import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../auth/AuthenticationProvider";
import { pathOr } from "ramda";
import { useScreen } from "../hooks/useScreen";
import { Div } from "../base/Div";
import { Icon } from "../base/Icon";
import { DrawerNavigation } from "@/layout/navigation/DrawerNavigation";
import { Drawer } from "@mui/material";

export const AuthenticatedLayout = ({ children, title }) => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const screenSize = useScreen();
  const [showNav, setShowNav] = useState(false);
  const useMUILeftNavDrawer = useFlag("MUILeftNavDrawer");

  return (
    <div className={`layout ss--${screenSize}`}>
      <header className={"page-head"}>
        <Div className={"logo"} hide={["sm", "nm"]}>
          <Link to={"/"}>
            <img alt={"Logo"} src={logo} />
          </Link>
        </Div>
        <Div className={"nav-toggle"} hide={["md", "lg"]}>
          <Icon
            i={`${showNav ? "navtoggle-open" : "navtoggle-closed"}`}
            size={"lg"}
            onClick={() => setShowNav((v) => !v)}
          />
        </Div>
        <Div className={"search"}>
          <Div hide={["sm", "nm"]}>
            <PageSearch />
          </Div>
          <Div
            className={"w-100 c-inv txt-a-c txt-lg txt-b"}
            hide={["md", "lg"]}
          >
            {title}
          </Div>
        </Div>
        <div className={"profile"} title={pathOr("", ["user", "email"], auth)}>
          <ProfileMenu />
        </div>
      </header>
      {!!useMUILeftNavDrawer ? (
        screenSize !== "sm" && screenSize !== "nm" ? (
          <DrawerNavigation />
        ) : showNav ? (
          <Drawer
            variant="temporary"
            open
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onClose: () => setShowNav(false),
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: "fit-content",
                minWidth: "300px",
                borderRadius: 0,
                height: "calc(100vh - var(--page-header-height))",
                maxHeight: "calc(100vh - var(--page-header-height))",
                zIndex: 14,
                top: "var(--page-header-height)",
              },
            }}
          >
            <DrawerNavigation />
          </Drawer>
        ) : null
      ) : (
        <Div
          className={`${
            screenSize === "sm" || screenSize === "nm"
              ? "toggle-nav"
              : "left-nav"
          } ${showNav ? "toggle-nav--open" : "toggle-nav--closed"}`}
        >
          <LeftNav />
        </Div>
      )}
      <main className={"content"}>
        <AppBreadcrumbs />
        {children}
      </main>
    </div>
  );
};
