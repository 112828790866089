import React from "react";
import { useParams, useMatch } from "react-router-dom";
import { Page } from "../../../../layout/Page";
import { Section } from "../../../../layout/Section";
import { useT } from "../../../../hooks/useT";
import { release } from "../../../../queries";
import { useQ } from "../../../../hooks/useQ";
import { clientUrl } from "../../../../util/routing";
import { uris } from "../../../../config/nav";
import { HighlightReportPdf } from "../../../projects/HighlightReportPdf";
import { isEmpty, isNil, path } from "ramda";
import { HighlightReport } from "../../../projects/components/HighlightReport";

export const ReleaseProgress = () => {
  const { id = "", reportId = "", type } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    release.single({ id }),
  );
  const { data: reportData = [] } = useQ(
    `release-${id}-report-highlights`,
    () => release.report.highlights({ id }),
  );
  const report = reportData
    .filter(
      (item, index) =>
        item.id === reportId ||
        (type === "highlight" && isNil(reportId)) ||
        (isEmpty(reportId) && index === 0 && type === "highlight"),
    )
    .map((item) => ({
      ...item,
      summary: path(["summary", "content"], item),
      value: path(["summary", "status"], item),
    }))[0];

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.progress.title")}>
        {useMatch(
          clientUrl(uris.release.progressFull, {
            id,
            type: "highlight",
            reportId,
          }),
        ) && (
          <HighlightReport
            initiative={data}
            report={report}
            initiativeType="release"
          />
        )}
        {useMatch(
          clientUrl(uris.release.progressReportIdPdf, {
            id,
            reportId,
          }),
        ) && (
          <HighlightReportPdf
            releaseId={id}
            reportId={reportId}
            reportType="release"
          />
        )}
      </Section>
    </Page>
  );
};
