import { useT } from "@/hooks/useT";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import React from "react";

export const BlockerAlert = ({ blocker }: any) => {
  const { t } = useT();
  return blocker.state === "blocked" ? (
    <Alert
      severity="warning"
      sx={{
        my: 2,
      }}
    >
      <AlertTitle>
        <Typography variant="h2">
          {t("generic.message.unsavedChanges")}
        </Typography>
      </AlertTitle>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => blocker.proceed()}>
          {t("generic.button.proceed")}
        </Button>
        <Button variant="outlined" onClick={() => blocker.reset()}>
          {t("generic.button.cancel")}
        </Button>
      </Stack>
    </Alert>
  ) : null;
};
