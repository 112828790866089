import React, { memo } from "react";
import { ToggleMenu } from "./ToggleMenu";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { useQ } from "@/hooks/useQ";
import { product, project } from "@/queries";

export const LinksNavigation = memo(
  ({ initiativeType }: { initiativeType: string }) => {
    const { t } = useT();
    const { id = "null" } = useParams();
    const { data: links = [] } = useQ(`${initiativeType}-${id}-links`, () =>
      (initiativeType === "project" ? project : product).links({ id }),
    );

    return (
      <ToggleMenu
        text={t("project.navigation.links")}
        startsOpen={false}
        items={(links ?? []).map((item) => ({
          id: item.title ?? item.label,
          label: item.title ?? item.label,
          to: item.address ?? item.href,
          externalUrl: true,
        }))}
      />
    );
  },
);
