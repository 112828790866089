import React, { memo, useState } from "react";
import { LinkItem } from "./DrawerNavigation";
import { useT } from "@/hooks/useT";
import {
  Location,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { clientUrl } from "@/util/routing";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function matchAllPaths(
  location: Location<any>,
  path: string,
  additionalMatchPaths: string[],
) {
  const exactMatchPath = matchPath(
    {
      path: path,
    },
    location.pathname,
  );
  if (exactMatchPath) {
    return exactMatchPath;
  } else {
    const additionals = additionalMatchPaths?.some((additionalPath) =>
      matchPath({ path: additionalPath }, location.pathname),
    );
    return additionals || null;
  }
}

const MenuLink = memo(
  ({
    item: {
      labelKey,
      label,
      to,
      params,
      children,
      end,
      additionalMatchPaths,
      onClick,
      externalUrl = false,
    },
    level = 1,
  }: {
    item: LinkItem;
    level?: number;
    externalUrl?: boolean;
  }) => {
    const { t } = useT();
    const navigate = useNavigate();
    const location = useLocation();

    const active =
      to && !externalUrl
        ? matchAllPaths(
            location,
            clientUrl(to, params) + (end ? "" : "/*"),
            additionalMatchPaths,
          )
        : false;

    const text = labelKey ? t(labelKey) : label;
    return (
      <>
        <ListItem
          sx={{
            py: 0,
            borderLeft: `${
              active && level === 1 ? "3px solid #666" : "2px solid #ccc"
            }`,
            pl: active && level === 1 ? 0 : "1px",
          }}
        >
          <ListItemButton
            sx={{
              pl: `${8 + (level - 1) * 10}px`,
            }}
            onClick={() =>
              onClick
                ? onClick()
                : externalUrl
                  ? window.open(to, "_blank").focus()
                  : navigate(clientUrl(to, params))
            }
          >
            <ListItemText
              primary={text}
              sx={{
                "& .MuiTypography-root": {
                  fontWeight: active && level > 1 ? "bold" : "normal",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        {active &&
          children &&
          children.map((subItem) => (
            <MenuLink
              item={subItem}
              level={level + 1}
              externalUrl={externalUrl}
            />
          ))}
      </>
    );
  },
);

export const ToggleMenu = memo(
  ({
    text,
    items,
    startsOpen = true,
    externalUrl = false,
    showFolder = false,
    ...props
  }: {
    text: string;
    items: LinkItem[];
    startsOpen?: boolean;
    externalUrl?: boolean;
    showFolder?: boolean;
  }) => {
    const [open, setOpen] = useState(startsOpen);

    return (
      <>
        <ListItem sx={{ py: 0 }} {...props}>
          <ListItemButton
            sx={{
              pl: 0,
            }}
            onClick={() => setOpen((t) => !t)}
          >
            <ListItemIcon
              sx={{
                minWidth: "auto",
                mr: 1,
              }}
            >
              {showFolder ? open ? <FolderOpenIcon /> : <FolderIcon /> : null}
            </ListItemIcon>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ fontWeight: 700, color: "black" }}
            />
            <ListItemIcon
              sx={{
                minWidth: "auto",
              }}
            >
              {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {open && (
          <List
            sx={{
              ml: 3,
              p: 0,
            }}
          >
            {items.map((item) => (
              <MenuLink key={item.id} item={item} externalUrl={externalUrl} />
            ))}
          </List>
        )}
      </>
    );
  },
);
