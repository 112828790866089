import React from "react";
import { useParams } from "react-router-dom";
import { P } from "../../../base/typography/P";
import { useT } from "../../../hooks/useT";
import { isNil } from "ramda";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useToast } from "../../../hooks/useToast";
import { translateStatusCode } from "../../../util/translateStatusCode";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { FormRadioSelector } from "../../../base/form/mui/FormRadioSelector";
import { ProfileIcon } from "../../../base/ProfileIcon";
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Stack,
} from "@mui/material";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";
import { CustomDialog } from "@/layout/CustomDialog";

const htmlContent = (id) => {
  const { t } = useT();
  return t(id) !== id ? (
    <Typography dangerouslySetInnerHTML={{ __html: t(id) }} />
  ) : undefined;
};

function removeTrailingSemicolon(content) {
  return content.slice(-1) === ";" ? content.slice(0, -1) : content;
}

function isEmailValid(email) {
  return (
    !isNil(email) &&
    email.indexOf("@") > 0 &&
    email.lastIndexOf(".") > email.indexOf("@")
  );
}

function getRoleAndLabel(array, category, tKey) {
  const { t } = useT();
  var arr = [];
  array.map((item) =>
    arr.push({
      value: item,
      label: t(tKey + "." + category + ".roles." + item + ".title"),
      tooltip: htmlContent(tKey + "." + category + ".roles." + item + ".help"),
    }),
  );
  return arr;
}

export function splitContact(contactInformation, existingContact) {
  console.log("Input: " + contactInformation);
  const content = removeTrailingSemicolon(contactInformation);
  let contact = {
    email: "",
    name: existingContact.name,
    validEmail: false,
  };
  if (content[content.length - 1] === ">" && content.indexOf("<") > 0) {
    contact.name = content.substring(0, content.indexOf("<") - 1);
    contact.email = content.substring(
      content.indexOf("<") + 1,
      content.length - 1,
    );
    if (contact.name.slice(0, 1) === '"' && contact.name.slice(-1) === '"') {
      contact.name = contact.name.slice(1, -1);
    }
  } else {
    contact.email = content;
  }
  contact.validEmail = isEmailValid(contact.email);
  console.log('Got "' + contactInformation + " -> " + JSON.stringify(contact));
  return contact;
}

export function updateForm(form, contact) {
  form("name", contact.name);
  form("email", contact.email);
}

export const OrganizationForm = ({
  onClose,
  validRoles,
  category,
  isEdit,
  setSelectedRole,
  toggle,
  roleId,
  name,
  role,
  email,
  initialCustomRole,
  formMethod,
  details,
  refetch,
  onSubmit,
  tKey = "project.organization",
  isOpen,
  ...props
}) => {
  const { id = "" } = useParams();
  const { t } = useT();
  const toast = useToast();
  const item = isEdit
    ? { email: email, name: name, role: role, details: details }
    : null;

  const addRole = useMutation(formMethod, {
    onSuccess: (data) => {
      toast.success(
        t(
          isEdit
            ? "project.organization.form.success.edit"
            : "project.organization.form.success.add",
        ),
      );
      refetch();
      toggle();
      reset();
    },
    onError: (data) => {
      toast.error(t(translateStatusCode(data.response.status)));
    },
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("E-Mail should be valid")
      .required(t("loginForm.fields.email.requiredError")),
    name: Yup.string().trim().required(),
    role: Yup.string().trim().required(),
    details: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: item?.email || "",
      name: item?.name || "",
      role: item?.role || "",
      details: item?.details || "",
    },
  });

  function onSave(data) {
    addRole.mutate({
      id: id,
      roleId: roleId,
      data,
    });
  }
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isDirty },
  } = formData;

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => toggle(false)}
      isContentDirty={isDirty}
    >
      <FormDataProvider
        formKey="project.organization.form.fields"
        {...formData}
      >
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              id: item?.id || undefined,
              email: data.email,
              name: data.name,
              role: data.role,
              details: data.details,
            });
          })}
        >
          <Stack spacing={3}>
            {!isEdit ? (
              <>
                <Stack direction={"row"} spacing={8} sx={{ width: "100%" }}>
                  <FormTextField
                    secondOnChange={(ev) =>
                      updateForm(
                        setValue,
                        splitContact(ev.target.value, getValues()),
                      )
                    }
                    name={"email"}
                    readOnly={isEdit}
                    fullWidth
                    required
                  />
                  <FormTextField
                    name={"name"}
                    readOnly={isEdit}
                    fullWidth
                    required
                  />
                </Stack>
                <FormRadioSelector
                  name={"role"}
                  items={getRoleAndLabel(validRoles, category, tKey)}
                  required
                />
              </>
            ) : (
              <Stack direction={"row"} spacing={1.5} sx={{ mt: 0, ml: "30px" }}>
                <ProfileIcon
                  name={item.name}
                  sx={{ height: "40px", width: "40px" }}
                />
                <Stack>
                  <Typography variant="h3">{item.name}</Typography>
                  <Typography variant="h4">{item.email}</Typography>
                </Stack>
              </Stack>
            )}
            <FormRichTextField name={"details"} fullWidth />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 3 }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => toggle(false)}
              >
                {isDirty
                  ? t("generic.button.cancel")
                  : t("generic.button.close")}
              </Button>
              <Button type="submit" variant="contained">
                {t("generic.button.save")}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};
