import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { uris } from "@/config/nav";
import { useT } from "@/hooks/useT";
import { clientUrl } from "@/util/routing";
import { useQ } from "@/hooks/useQ";
import { product } from "@/queries";
import { Divider } from "@mui/material";
import { useHighlightReports } from "./useHighlightReports";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { LinksNavigation } from "./LinksNavigation";
import { ActionsNavigation } from "./ActionsNavigation";
import { StandardProgressNavigation } from "./StandardProgressNavigation";

const ProductReportsNavigation = memo(
  ({ lastHighlightReportId }: { lastHighlightReportId: string }) => {
    const { t } = useT();
    const { id = "null" } = useParams();

    return (
      <ToggleMenu
        text={t("project.navigation.reports.title")}
        startsOpen={false}
        items={[
          {
            id: "highlightReports",
            label: t("project.navigation.reports.highlightReports"),
            to: clientUrl(uris.product.progressFull, {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            }),
          },
        ]}
      />
    );
  },
);

export const ProductNavigation = memo(
  ({ progressMenuOpen }: { progressMenuOpen: boolean }) => {
    const { id = "null", reportId, type = "highlight", version } = useParams();
    const { data: productData = {} } = useQ(`product-${id}`, () =>
      product.single({ id }),
    );
    const level = productData?.level ?? 3;
    const { reports, lastHighlightReportId } = useHighlightReports(
      id,
      reportId,
      "product",
    );

    const items: LinkItem[] = [
      {
        id: "home",
        labelKey: "project.navigation.home",
        to: uris.product.single,
        params: { id },
        end: true,
      },
      {
        id: "roadmap",
        labelKey: "product.navigation.roadmap",
        to: uris.product.roadmap,
        params: { id },
        minLevel: 3,
      },
      {
        id: "risk",
        labelKey: "project.navigation.risk.title",
        to: uris.product.risk,
        params: { id },
        minLevel: 2,
      },
      {
        id: "organization",
        labelKey: "project.navigation.organization.title",
        to: uris.product.organization,
        params: { id },
      },
      {
        id: "change",
        labelKey: "project.navigation.change.title",
        to: uris.product.change,
        params: { id },
        minLevel: 2,
      },

      {
        id: "progress",
        labelKey: "project.navigation.progress",
        to: uris.product.progressFull,
        params: {
          id,
          reportId:
            reportId ?? reports?.[0]?.id ?? lastHighlightReportId ?? "noReport",
          type,
        },
        additionalMatchPaths: [uris.product.progressType + "/*"],
        children: [
          {
            id: "progress.highlight",
            labelKey: "project.progress.highlightReport.title",
            to: uris.product.progressFull,
            params: {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            },
            additionalMatchPaths: [
              clientUrl(uris.product.progressType, {
                id,
                type: "highlight",
              }) + "/*",
            ],
          },
        ],
      },
    ];

    return (
      <>
        <ToggleMenu
          data-testid="ProductNav"
          text={productData?.name ?? "Product"}
          items={items.filter((item) =>
            typeof item.minLevel !== "undefined"
              ? level >= item.minLevel
              : true,
          )}
          showFolder
        />
        <Divider sx={{ mt: 1 }} />
        <LinksNavigation initiativeType="product" />
        {progressMenuOpen ? (
          <StandardProgressNavigation
            summaryLink={uris.product.progressFull}
            fullscreenLink={uris.product.progressReportId}
            pdfLink={uris.product.progressReportIdPdf}
            reports={reports}
            editReportLink={uris.product.editProgress}
            createReportLink={uris.product.createProgress}
            viewReportLink={uris.product.progressFull}
            lastHighlightReportId={lastHighlightReportId}
            dataTestId="ProductProgressNav"
          />
        ) : (
          <>
            <ProductReportsNavigation
              lastHighlightReportId={lastHighlightReportId}
            />
            <ActionsNavigation
              editPropertiesLink={uris.product.editProperties}
              openLogLink={uris.product.log}
              editLinksLink={`/products/:id/editLinks`}
            />
          </>
        )}
      </>
    );
  },
);
