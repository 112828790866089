import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { PrimaryButton, SecondaryButton } from "../../base/Button";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import * as Yup from "yup";
import { useT } from "../../hooks/useT";
import { useMutation } from "react-query";
import {
  project,
  location,
  projectType,
  category,
  department,
  currency,
  programme,
} from "../../queries";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";
import { Hide } from "../../base/responsive/Hide";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { useQ } from "../../hooks/useQ";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "../../base/form/mui/FormTextField";
import { FormSelect } from "../../base/form/mui/FormSelect";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { Stack, Grid } from "@mui/material";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";
import { BlockerAlert } from "@/base/BlockerAlert";

export const NewProject = () => {
  const { t } = useT();
  const { portfolioId, programmeId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: portfolios = [], isLoading } = useQ(`portfolios`, () =>
    portfolio.list(),
  );
  const { data: locations = [], isLoading: isLoadingLocations } = useQ(
    `locations`,
    () => location.list(),
  );

  const { data: projectTypes = [], isLoading: isLoadingProjectTypes } = useQ(
    `projectTypes`,
    () => projectType.list(),
  );
  const { data: categories = [], isLoading: isLoadingCategories } = useQ(
    `categories`,
    () => category.list(),
  );

  const { data: departments = [], isLoading: isLoadingDepartments } = useQ(
    `departments`,
    () => department.list(),
  );
  const { data: currencies = [], isLoading: isLoadingCurrencies } = useQ(
    `currencies`,
    () => currency.list(),
  );
  const { data: programmes = [] } = useQ(`programmes`, () => programme.list());

  function getOptions(array) {
    return array.map((e) => {
      return { label: e.name, value: e.id };
    });
  }

  const addProject = useMutation(project.add, {
    onSuccess: (data) => {
      navigate(clientUrl(uris.project.single, data));
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("projectForm.fields.name.requiredError")),
    identifier: Yup.string().trim(),
    portfolio: Yup.string().trim(),
    location: Yup.string().trim(),
    projectType: Yup.string().trim(),
    category: Yup.string().trim(),
    department: Yup.string().trim(),
    currency: Yup.string().trim(),
    background: Yup.string().trim(),
    timing: Yup.string().trim(),
    bigPicture: Yup.string().trim(),
    programme: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      portfolio: "",
      location: "",
      projectType: "",
      category: "",
      department: "",
      currency: "",
      programme: "none",
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = formData;

  useEffect(() => {
    setValue(
      "portfolio",
      portfolioId ?? (portfolios.length !== 0 ? portfolios[0].id : ""),
    );
    setValue("programme", programmeId ?? "");
    setValue("location", locations.length !== 0 ? locations[0].id : "");
    setValue(
      "projectType",
      projectTypes.length !== 0 ? projectTypes[0].id : "",
    );
    setValue("category", categories.length !== 0 ? categories[0].id : "");
    setValue("department", departments.length !== 0 ? departments[0].id : "");
    setValue("currency", currencies.length !== 0 ? currencies[0] : "");
  }, [
    portfolioId,
    isLoading,
    isLoadingLocations,
    isLoadingProjectTypes,
    isLoadingCategories,
    isLoadingDepartments,
    isLoadingCurrencies,
  ]);

  useConfirmLeavingPage(isDirty);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !!isDirty &&
      !isSubmitting,
  );

  return (
    <Page>
      <NoMobileView show={"sm"} />

      <Hide hide={"sm"}>
        <FormDataProvider formKey="initiative.addEditForm.fields" {...formData}>
          <BlockerAlert blocker={blocker} />
          <form
            onSubmit={handleSubmit((data) => {
              setIsSubmitting(true);
              addProject.mutate({
                ...data,
                programme: data.programme === "none" ? "" : data.programme,
              });
            })}
          >
            <Section title={t("project.addProject")}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTextField name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField name="identifier" />
                </Grid>

                {portfolios.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="portfolio"
                      options={getOptions(portfolios)}
                    />
                  </Grid>
                ) : null}

                {locations.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="location"
                      options={getOptions(locations)}
                    />
                  </Grid>
                ) : null}

                {projectTypes.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="projectType"
                      options={getOptions(projectTypes)}
                    />
                  </Grid>
                ) : null}

                {departments.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="department"
                      options={getOptions(departments)}
                    />
                  </Grid>
                ) : null}

                {categories.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="category"
                      options={getOptions(categories)}
                    />
                  </Grid>
                ) : null}

                {currencies.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="currency"
                      options={currencies.map((e) => {
                        return { label: e, value: e };
                      })}
                    />
                  </Grid>
                ) : null}

                {programmes.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="programme"
                      options={[
                        {
                          label: t(
                            "initiative.addEditForm.fields.programme.none",
                          ),
                          value: "none",
                        },
                        ...programmes.map((programme) => {
                          return {
                            label: `${t(
                              "initiative.addEditForm.fields.programme.present",
                            )}${programme.name}`,
                            value: programme.id,
                          };
                        }),
                      ]}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Section>

            <Section title={t("projectForm.background.title")}>
              <Stack spacing={2}>
                <FormRichTextField name={"background"} />
                <FormRichTextField name={"timing"} />
                <FormRichTextField name={"bigPicture"} />
              </Stack>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <SecondaryButton
                  onClick={() => {
                    navigate(-2);
                  }}
                >
                  {t("generic.button.cancel")}
                </SecondaryButton>
                <PrimaryButton className={"mg-l-nm"} type={"submit"}>
                  {t("projectForm.submit.create")}
                </PrimaryButton>
              </Stack>
            </Section>
          </form>
        </FormDataProvider>
      </Hide>
    </Page>
  );
};
