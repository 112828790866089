import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { uris } from "@/config/nav";
import { useQ } from "@/hooks/useQ";
import { release } from "@/queries";
import { Divider } from "@mui/material";
import { useHighlightReports } from "./useHighlightReports";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { ActionsNavigation } from "./ActionsNavigation";
import { StandardProgressNavigation } from "./StandardProgressNavigation";
import { clientUrl } from "@/util/routing";

export const ReleaseNavigation = memo(
  ({ progressMenuOpen }: { progressMenuOpen: boolean }) => {
    const { id = "null", reportId, type = "highlight", version } = useParams();
    const { data: releaseData = {} } = useQ(`release-${id}`, () =>
      release.single({ id }),
    );
    const level = releaseData?.level ?? 3;
    const { reports, lastHighlightReportId } = useHighlightReports(
      id,
      reportId,
      "release",
    );

    const items: LinkItem[] = [
      {
        id: "home",
        labelKey: "project.navigation.home",
        to: uris.release.single,
        params: { id },
        end: true,
      },
      {
        id: "plans",
        labelKey: "project.navigation.plans.title",
        to: uris.release.plan,
        minLevel: 3,
        params: { id },
      },
      {
        id: "risk",
        labelKey: "project.navigation.risk.title",
        to: uris.release.risk,
        minLevel: 2,
        params: { id },
      },
      {
        id: "change",
        labelKey: "project.navigation.change.title",
        to: uris.release.change,
        minLevel: 2,
        params: { id },
      },
      {
        id: "progress",
        labelKey: "project.navigation.progress",
        to: uris.release.progressFull,
        params: {
          id,
          reportId:
            reportId ?? reports?.[0]?.id ?? lastHighlightReportId ?? "noReport",
          type,
        },
        additionalMatchPaths: [uris.release.progress + "/*"],
        children: [
          {
            id: "progress.highlight",
            labelKey: "project.progress.highlightReport.title",
            to: uris.release.progressFull,
            params: {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            },
            additionalMatchPaths: [
              clientUrl(uris.release.progress, {
                id,
                type: "highlight",
              }) + "/*",
            ],
            end: true,
          },
        ],
      },
    ];

    return (
      <>
        <ToggleMenu
          data-testid="ReleaseNav"
          text={releaseData.name ?? "Release"}
          items={items.filter((item) =>
            typeof item.minLevel !== "undefined"
              ? level >= item.minLevel
              : true,
          )}
          showFolder
        />
        <Divider sx={{ mt: 1 }} />
        {progressMenuOpen ? (
          <StandardProgressNavigation
            summaryLink={uris.release.progressFull}
            fullscreenLink={uris.release.progress}
            pdfLink={uris.release.progressReportIdPdf}
            reports={reports}
            editReportLink={uris.release.editProgress}
            createReportLink={uris.release.createProgress}
            viewReportLink={uris.release.progressFull}
            lastHighlightReportId={lastHighlightReportId}
            dataTestId="ReleaseProgressNav"
          />
        ) : (
          <ActionsNavigation openLogLink={uris.release.log} />
        )}
      </>
    );
  },
);
