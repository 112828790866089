import React, { memo } from "react";
import { List } from "@mui/material";

import { matchPath, useLocation } from "react-router-dom";
import { uris } from "@/config/nav";

import { ProductNavigation } from "./ProductNavigation";
import { ReleaseNavigation } from "./ReleaseNavigation";
import { ProgrammeNavigation } from "./ProgrammeNavigation";
import { ProjectNavigation } from "./ProjectNavigation";
import { LessonsNavigation } from "./LessonsNavigation";
import { PortfolioNavigation } from "./PortfolioNavigation";
import { AccountSettingsNavigation } from "./AccountSettingsNavigation";

export type LinkItem = {
  id: string;
  labelKey?: string;
  label?: string;
  minLevel?: number;
  to?: string;
  additionalMatchPaths?: string[];
  params?: any;
  children?: LinkItem[];
  end?: boolean;
  externalUrl?: boolean;
  onClick?: () => void;
};

export const DrawerNavigation = memo(() => {
  const location = useLocation();

  const match = (path: string) =>
    matchPath(
      {
        path,
      },
      location.pathname,
    );

  const isProject = match(uris.project.single + "/*");
  const isProjectProgress = match(uris.project.progressType + "/*");
  const isProjectBrief = match(uris.project.brief + "/*");
  const isPid = match(uris.project.pid + "/*");
  const isPidBrief = isProjectBrief || isPid;

  const isProduct = match(uris.product.single + "/*");
  const isProductProgress = match(uris.product.progressType + "/*");

  const isProgramme = match(uris.programme.single + "/*");
  const isProgrammeProgress = match(uris.programme.knowledge + "/*");

  const isRelease = match(uris.release.single + "/*");
  const isReleaseProgress = match(uris.release.progress + "/*");

  const isAccountSettings = match(uris.settings.main + "/*");

  const notFound =
    !isProject &&
    !isProduct &&
    !isProgramme &&
    !isRelease &&
    !isAccountSettings;

  return (
    <List>
      {notFound && (
        <>
          <PortfolioNavigation />
          <LessonsNavigation />
        </>
      )}
      {isProject && (
        <ProjectNavigation
          progressMenuOpen={!!isProjectProgress}
          pidBriefMenuOpen={!!isPidBrief}
        />
      )}
      {isProduct && (
        <ProductNavigation progressMenuOpen={!!isProductProgress} />
      )}
      {isProgramme && (
        <ProgrammeNavigation progressMenuOpen={!!isProgrammeProgress} />
      )}
      {isRelease && (
        <ReleaseNavigation progressMenuOpen={!!isReleaseProgress} />
      )}
      {isAccountSettings && <AccountSettingsNavigation />}
    </List>
  );
});
