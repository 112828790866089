import React, { memo } from "react";
import { ToggleMenu } from "./ToggleMenu";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { propOr } from "ramda";

export const StandardProgressNavigation = memo(
  ({
    summaryLink,
    fullscreenLink,
    pdfLink,
    reports,
    lastCheckpointReportId,
    lastHighlightReportId,
    lastEndStageReportId,
    lastEndProjectReportId,
    editReportLink,
    createReportLink,
    viewReportLink,
    dataTestId,
  }: {
    summaryLink: string;
    fullscreenLink: string;
    pdfLink: string;
    reports: any[];
    lastCheckpointReportId?: string;
    lastHighlightReportId?: string;
    lastEndStageReportId?: string;
    lastEndProjectReportId?: string;
    editReportLink: string;
    createReportLink: string;
    viewReportLink: string;
    dataTestId: string;
  }) => {
    const { t } = useT();
    const {
      id = "null",
      reportId = "noReport",
      type = "highlight",
    } = useParams();

    return (
      <>
        <ToggleMenu
          data-testid={dataTestId}
          text={t("navigation.projects.progress.view.title")}
          items={[
            {
              id: "summary",
              label: t("navigation.projects.progress.view.summary"),
              to: clientUrl(summaryLink, {
                id,
                type,
                reportId,
              }),
            },
            {
              id: "fullscreen",
              label: t("navigation.projects.progress.view.fullscreen"),
              onClick: () => {
                window
                  .open(
                    clientUrl(fullscreenLink, {
                      id,
                      type,
                      reportId,
                    }),
                    "_blank",
                  )
                  .focus();
              },
            },
            {
              id: "pdf",
              label: t("navigation.projects.progress.view.pdf"),
              to: clientUrl(pdfLink, {
                id,
                reportId,
              }),
            },
          ]}
        />
        <ToggleMenu
          text={t("navigation.projects.progress.actions.title")}
          items={[
            ...(reportId &&
            reportId !== "noReport" &&
            [
              type === "checkpoint" && lastCheckpointReportId,
              type === "highlight" && lastHighlightReportId,
              type === "endStage" && lastEndStageReportId,
              type === "endProject" && lastEndProjectReportId,
            ].find((element) => element === reportId)
              ? [
                  {
                    id: "editReport",
                    label: t("navigation.projects.progress.actions.editReport"),
                    to: clientUrl(editReportLink, {
                      id,
                      reportId,
                      type,
                    }),
                  },
                ]
              : []),
            {
              id: "createReport",
              label: t("navigation.projects.progress.actions.createReport"),
              to: clientUrl(createReportLink, {
                id,
                type,
              }),
            },
          ]}
        />
        <ToggleMenu
          text={t("navigation.projects.progress.select.title")}
          items={reports.map((report) => ({
            id: report.id,
            label: propOr("", "name", report),
            to: clientUrl(viewReportLink, {
              id,
              reportId: report?.id,
              type,
            }),
            children:
              report.children?.map((child) => ({
                id: child.id,
                label: propOr("", "name", child),
                to: clientUrl(viewReportLink, {
                  id,
                  reportId: child?.id,
                  type,
                }),
                children: child.children,
                additionalMatchPaths: [
                  clientUrl(pdfLink, {
                    id,
                    reportId: report?.id,
                  }),
                ],
              })) ?? [],
            additionalMatchPaths: [
              clientUrl(pdfLink, {
                id,
                reportId: report?.id,
              }),
              ...(report.children?.map((child) =>
                clientUrl(viewReportLink, {
                  id,
                  reportId: child?.id,
                  type,
                }),
              ) ?? []),
            ],
          }))}
        />
      </>
    );
  },
);
