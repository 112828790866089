import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { uris } from "@/config/nav";
import { useQ } from "@/hooks/useQ";
import { programme } from "@/queries";
import { Divider } from "@mui/material";
import { useHighlightReports } from "./useHighlightReports";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { ActionsNavigation } from "./ActionsNavigation";
import { StandardProgressNavigation } from "./StandardProgressNavigation";
import { clientUrl } from "@/util/routing";

export const ProgrammeNavigation = memo(
  ({ progressMenuOpen }: { progressMenuOpen: boolean }) => {
    const { id = "null", reportId, type = "highlight", version } = useParams();
    const { data: programmeData = {} } = useQ(`programme-${id}`, () =>
      programme.single({ id }),
    );
    const level = programmeData?.level ?? 3;
    const { reports, lastHighlightReportId } = useHighlightReports(
      id,
      reportId,
      "programme",
    );

    const items: LinkItem[] = [
      {
        id: "home",
        labelKey: "project.navigation.home",
        to: uris.programme.single,
        params: { id },
        end: true,
      },
      {
        id: "organization",
        labelKey: "programme.navigation.organization.title",
        to: uris.programme.organization,
        params: { id },
      },
      {
        id: "design",
        labelKey: "programme.navigation.design.title",
        to: uris.programme.design,
        params: { id },
      },
      {
        id: "justification",
        labelKey: "programme.navigation.justification.title",
        to: uris.programme.editDefinition,
        params: { id },
      },
      {
        id: "structure",
        labelKey: "programme.navigation.structure.title",
        to: uris.programme.editDefinition,
        params: { id },
      },
      {
        id: "knowledge",
        labelKey: "programme.navigation.knowledge.title",
        to: uris.programme.knowledgeFull,
        params: {
          id,
          reportId:
            reportId ?? reports?.[0]?.id ?? lastHighlightReportId ?? "noReport",
          type,
        },
        additionalMatchPaths: [uris.programme.knowledgeType + "/*"],
        children: [
          {
            id: "progress.highlight",
            labelKey: "project.progress.highlightReport.title",
            to: uris.programme.knowledgeFull,
            params: {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            },
            additionalMatchPaths: [
              clientUrl(uris.programme.knowledgeType, {
                id,
                type: "highlight",
              }) + "/*",
            ],
            end: true,
          },
        ],
      },
      {
        id: "assurance",
        labelKey: "programme.navigation.assurance.title",
        to: uris.programme.risk,
        params: { id },
        children: [
          {
            id: "risk.register",
            labelKey: "project.navigation.risk.register",
            to: uris.programme.risk,
            params: { id },
            end: true,
          },
          {
            id: "risk.approach",
            labelKey: "project.navigation.risk.approach",
            to: uris.programme.riskApproach,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "decisions",
        labelKey: "programme.navigation.decisions.title",
        to: uris.programme.change,
        params: { id },
        children: [
          {
            id: "change.issueRegister",
            labelKey: "project.navigation.change.issueRegister",
            to: uris.programme.change,
            params: { id },
            end: true,
          },
          {
            id: "change.approach",
            labelKey: "project.navigation.change.approach",
            to: uris.programme.changeApproach,
            params: { id },
            end: true,
          },
        ],
      },
    ];

    return (
      <>
        <ToggleMenu
          data-testid="ProgrammeNav"
          text={programmeData.name ?? "Programme"}
          items={items.filter((item) =>
            typeof item.minLevel !== "undefined"
              ? level >= item.minLevel
              : true,
          )}
          showFolder
        />
        <Divider sx={{ mt: 1 }} />
        {progressMenuOpen ? (
          <StandardProgressNavigation
            summaryLink={uris.programme.knowledgeFull}
            fullscreenLink={uris.programme.knowledge}
            pdfLink={uris.programme.knowledgeReportIdPdf}
            reports={reports}
            editReportLink={uris.programme.editKnowledge}
            createReportLink={uris.programme.createKnowledge}
            viewReportLink={uris.programme.knowledgeFull}
            lastHighlightReportId={lastHighlightReportId}
            dataTestId="ProgrammeProgressNav"
          />
        ) : (
          <ActionsNavigation
            editPropertiesLink={uris.programme.editProperties}
            openLogLink={uris.programme.log}
          />
        )}
      </>
    );
  },
);
