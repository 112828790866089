import { useQ } from "@/hooks/useQ";
import { useT } from "@/hooks/useT";
import { product, programme, release } from "@/queries";
import moment from "moment";
import { useMemo } from "react";

export function compareReportsByDateAndDraftStatus(a, b) {
  if (a.isDraft) return 1;
  if (b.isDraft) return -1;
  if (
    moment(moment(a.date).format("YYYY-MM-DD")).isSame(
      moment(b.date).format("YYYY-MM-DD"),
    )
  )
    return moment(a.timestamp).isBefore(moment(b.timestamp)) ? -1 : 1;

  return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
}

export function isSameDate(previous, current) {
  return moment(previous.to || previous.date).isSame(
    moment(current.to || current.date),
    "date",
  );
}

export function enrichReportWithVersion(report, index, allReports) {
  const isSameDateAsPrevious =
    index > 0 && isSameDate(allReports[index - 1], report);
  const version = isSameDateAsPrevious
    ? allReports
        .slice(0, index)
        .filter((prevReport) => isSameDate(prevReport, report)).length + 1
    : undefined;

  return {
    ...report,
    to: report.to || report.date,
    date: formatDate(report.to || report.date),
    version,
  };
}

function formatDate(date) {
  return new Date(date).toISOString().slice(0, 10);
}

export function groupReportsByDate(reports) {
  const enrichedReports = reports
    .slice()
    .sort(compareReportsByDateAndDraftStatus)
    .map(enrichReportWithVersion);
  const groupedReports = {};

  enrichedReports.forEach((report) => {
    const date = report.date;
    if (!groupedReports[date]) {
      groupedReports[date] = [];
    }
    groupedReports[date].push(report);
  });

  return Object.values(groupedReports).map((group: any) => {
    const highestVersionReport = group.reduce((prev, current) =>
      prev.version > current.version ? prev : current,
    );
    highestVersionReport.children = group
      .filter((report) => report.version !== highestVersionReport.version)
      .reverse();
    return highestVersionReport;
  });
}

export const useHighlightReports = (
  id: string,
  reportId: string,
  initiative: string,
) => {
  const { t } = useT();

  const { data: highlights = [], isFetched } = useQ(
    `${initiative}-${id}-report-highlights`,
    () =>
      (initiative === "product"
        ? product
        : initiative === "programme"
          ? programme
          : release
      ).report.highlights({
        id,
      }),
  );

  const reports = useMemo(() => {
    const groupedReports = groupReportsByDate(highlights);
    return groupedReports.slice().reverse().map(formatReportForDisplay);
  }, [highlights, reportId]);

  function filterUniqueOrRelevantReports(report, index, allReports) {
    return (
      index === 0 ||
      !isSameDate(allReports[index - 1], report) ||
      report.id === reportId ||
      allReports
        .map((item) => (isSameDate(item, report) ? item.id : undefined))
        .includes(reportId)
    );
  }

  function formatReportForDisplay(report) {
    const versionLabel = report.version ? ` (v${report.version})` : "";
    const draftLabel = report.isDraft
      ? ` (${t("project.navigation.reports.draft")})`
      : "";

    return {
      name: `${report.date}${versionLabel}${draftLabel}`,
      id: report.id,
      children:
        report.children?.map((child) => formatReportForDisplay(child)) ?? [],
    };
  }

  function lastReportId(reports) {
    const lastReport = reports
      .slice()
      .sort((a, b) =>
        a.isDraft
          ? 1
          : b.isDraft
            ? -1
            : moment(a.date).isBefore(moment(b.date)) || a.to < b.to
              ? -1
              : moment(b.date).isBefore(moment(a.date)) || b.to < a.to
                ? 1
                : a.timestamp < b.timestamp
                  ? -1
                  : a.timestamp > b.timestamp
                    ? 1
                    : 0,
      )
      .slice(0);
    return lastReport.length > 0 ? lastReport[0].id : undefined;
  }

  const lastHighlightReportId = useMemo(() => {
    return isFetched && lastReportId(reports) !== undefined
      ? lastReportId(reports)
      : "noReport";
  }, [isFetched, reports]);

  return {
    reports,
    lastHighlightReportId,
  };
};
