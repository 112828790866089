import React, { memo } from "react";
import { ToggleMenu } from "./ToggleMenu";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { clientUrl } from "@/util/routing";

export const ActionsNavigation = memo(
  ({
    editPropertiesLink,
    openLogLink,
    editLinksLink,
    settingsLink,
  }: {
    editPropertiesLink?: string;
    openLogLink?: string;
    editLinksLink?: string;
    settingsLink?: string;
  }) => {
    const { t } = useT();
    const { id = "null" } = useParams();

    return (
      <ToggleMenu
        data-testid="ActionsNav"
        text={t("project.navigation.actions.title")}
        startsOpen={true}
        items={[
          ...(editPropertiesLink
            ? [
                {
                  id: "editProperties",
                  label: t("project.navigation.actions.editProperties"),
                  to: clientUrl(editPropertiesLink, { id }),
                },
              ]
            : []),
          ...(openLogLink
            ? [
                {
                  id: "openLog",
                  label: t("project.navigation.actions.openLog"),
                  to: clientUrl(openLogLink, { id }),
                },
              ]
            : []),
          ...(editLinksLink
            ? [
                {
                  id: "editLinks",
                  label: t("project.navigation.actions.editLinks"),
                  to: clientUrl(editLinksLink, { id }),
                },
              ]
            : []),
          ...(settingsLink
            ? [
                {
                  id: "settings",
                  label: t("project.navigation.actions.settings"),
                  to: clientUrl(settingsLink, { id }),
                },
              ]
            : []),
        ]}
      />
    );
  },
);
