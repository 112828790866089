import React, { memo } from "react";
import { uris } from "@/config/nav";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { useT } from "@/hooks/useT";

export const AccountSettingsNavigation = memo(() => {
  const { t } = useT();
  const items: LinkItem[] = [
    {
      id: "users",
      labelKey: "navigation.accountSettings.users",
      to: uris.settings.users,
      end: true,
    },
    {
      id: "properties",
      labelKey: "navigation.accountSettings.properties",
      to: uris.settings.properties,
    },
    {
      id: "projectSettings",
      labelKey: "navigation.accountSettings.projectSettings",
      to: uris.settings.projectSettings,
    },
    {
      id: "portfolios",
      labelKey: "navigation.accountSettings.portfolios",
      to: uris.settings.portfolios,
    },
    {
      id: "lifecycles",
      labelKey: "navigation.accountSettings.lifecycles",
      to: uris.settings.lifecycles,
    },
    {
      id: "tailoring",
      labelKey: "navigation.accountSettings.tailoring",
      to: uris.settings.tailoring,
    },
    {
      id: "dpa",
      labelKey: "navigation.accountSettings.dpa",
      to: uris.settings.dpa,
    },
  ];

  return (
    <ToggleMenu
      text={t("navigation.accountSettings.title")}
      items={items}
      showFolder
    />
  );
});
