// Renamed the module in package.json. There is a version conflict with the one that
// react-router-dom brings with it.
import { compile } from "path-to-regexp-resolve";

export const clientUrl = (url, params) => {
  try {
    const toPath = compile(url);
    return toPath(params);
  } catch (error) {
    console.log("[ERROR] clientUrl failed for ", url, params, error);
    throw error;
  }
};
