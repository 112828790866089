import { CustomAccordion } from "@/layout/CustomAccordion";
import { Section } from "@/layout/Section";
import { Page } from "@/layout/Page";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useQ } from "@/hooks/useQ";
import { portfolio } from "@/queries";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { useT } from "@/hooks/useT";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { isEmpty } from "ramda";
import { CustomDialog } from "@/layout/CustomDialog";
import { DeleteButton } from "@/base/DeleteButton";
import { EditOutlined } from "@mui/icons-material";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";

const PortfolioForm = ({ portfolio, open, onSave, onClose }) => {
  const { t } = useT();

  const schema = yup.object().shape({
    name: yup.string().optional(),
    description: yup.string().optional(),
    accessControl: yup.array().of(yup.string()).optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: portfolio?.name || "",
      description: portfolio?.description || "",
      accessControl: portfolio?.accessControl || [],
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = formData;
  const [accessControl] = useWatch({
    control,
    name: ["accessControl"],
  });

  useEffect(() => {
    reset({
      name: portfolio?.name || "",
      description: portfolio?.description || "",
      accessControl: portfolio?.accessControl || [],
    });
  }, [reset, portfolio]);

  useConfirmLeavingPage(isDirty);

  return (
    <CustomDialog isOpen={open} onClose={onClose}>
      <FormDataProvider formKey="accountSetting.portfolio.fields" {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              id: portfolio?.id || undefined,
              ...data,
              accessControl: !isEmpty(data.accessControl)
                ? data.accessControl
                : undefined,
            });
          })}
        >
          <Stack spacing={2}>
            <FormTextField name={"name"} fullWidth />
            <FormTextField name={"description"} rows="5" multiline fullWidth />
          </Stack>
          <Stack spacing={1} sx={{ mt: 3 }} alignItems="flex-start">
            <Typography
              sx={{
                fontSize: "16px !important",
                color: "#111827 !important",
              }}
            >
              {t("accountSetting.portfolio.fields.domainAccess.title")} *
            </Typography>
            {(accessControl ?? []).map((_, i) => (
              <Stack direction="row" spacing={2}>
                <FormTextField
                  name={`accessControl.${i}`}
                  variant="outlined"
                  label=""
                  help=""
                  placeholder=""
                />
                <Button
                  variant="text"
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() =>
                    setValue(
                      "accessControl",
                      accessControl.filter((_, j) => j !== i),
                    )
                  }
                />
              </Stack>
            ))}
            <Button
              sx={{ padding: "5px" }}
              variant="text"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setValue("accessControl", [...accessControl, ""]);
              }}
            >
              {t("accountSetting.portfolio.fields.domainAccess.add")}
            </Button>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 2 }}
            >
              <Button variant="contained" color="secondary" onClick={onClose}>
                {isDirty
                  ? t("generic.button.cancel")
                  : t("generic.button.close")}
              </Button>
              <Button type="submit" variant="contained">
                {t("generic.button.save")}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

const PortfolioDetails = ({ portfolio, onEdit, onDelete }) => {
  const { t } = useT();
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <DeleteButton
          onConfirm={onDelete}
          content="Are you sure you want to delete this portfolio?"
        />
        <Button
          variant="text"
          color="secondary"
          startIcon={<EditOutlined />}
          onClick={onEdit}
        >
          {t("generic.button.edit")}
        </Button>
      </Stack>
      <Typography variant="h2">{portfolio.name}</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: portfolio.description }} />
      {(portfolio?.accessControl ?? []).map((domain, i) => (
        <Typography>- {domain}</Typography>
      ))}
    </Stack>
  );
};

export const Portfolios = () => {
  const { data: portfolios = [], refetch: refetchPortfolios } = useQ(
    `portfolios`,
    () => portfolio.list(),
  );

  const toast = useToast();
  const { t } = useT();
  const createPortfolios = useMutation(portfolio.create, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Portfolios created successfully");
      refetchPortfolios();
      setShowNewItemForm(false);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating portfolios");
    },
  });

  const updatePortfolios = useMutation(portfolio.update, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Portfolios updated successfully");
      refetchPortfolios();
      setEditItemId(null);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating portfolios");
    },
  });

  const deletePortfolios = useMutation(portfolio.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Portfolios deleted successfully");
      refetchPortfolios();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting portfolios");
    },
  });

  const updateAccessControl = useMutation(portfolio.accessControl, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Portfolios updated successfully");
      refetchPortfolios();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating portfolios");
    },
  });

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [editItemId, setEditItemId] = useState(null);

  const handleShowEditItemForm = useCallback(
    (itemId) => {
      setShowNewItemForm(false);
      setEditItemId(itemId);
    },
    [portfolios],
  );

  const handleShowDeleteItemForm = useCallback((itemId) => {
    setShowNewItemForm(false);
    setEditItemId(null);
  }, []);

  return (
    <Page>
      <Section title={t("page.home.portfolios.title")}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography>{t("accountSetting.portfolio.description")}</Typography>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              setShowNewItemForm((s) => !s);
              setViewItemId(null);
            }}
          >
            {t("accountSetting.portfolio.add")}
          </Button>
        </Stack>
        <CustomAccordion
          tableView
          header={{
            expanded: false,
            columns: [
              {
                title: t("accountSetting.portfolio.fields.name.title"),
                flex: 1,
              },
            ],
            details: (
              <PortfolioForm
                open={showNewItemForm}
                onSave={createPortfolios.mutate}
                onClose={() => {
                  setShowNewItemForm(false);
                }}
              />
            ),
          }}
          rows={portfolios.map((portfolio) => ({
            id: portfolio.id,
            columns: [
              {
                title: portfolio.name,
                flex: 1,
              },
              {
                content:
                  portfolio.accessControl !== undefined &&
                  portfolio.accessControl !== null &&
                  portfolio.accessControl.length ? (
                    <LockOutlinedIcon />
                  ) : null,
                width: 40,
              },
            ],
            details: (
              <PortfolioDetails
                portfolio={portfolio}
                onEdit={() => {
                  handleShowEditItemForm(portfolio.id);
                }}
                onDelete={() => deletePortfolios.mutate({ id: portfolio.id })}
              />
            ),
          }))}
        />
        <PortfolioForm
          open={editItemId !== null}
          portfolio={portfolios.find((p) => p.id === editItemId)}
          onClose={() => {
            setShowNewItemForm(false);
            handleShowEditItemForm(null);
          }}
          onSave={(data) => {
            updatePortfolios.mutate(data);
          }}
        />
      </Section>
    </Page>
  );
};
