import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Divider,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
} from "@mui/material";
import { BlockChart } from "../../../base/charts/BlockChart";
import { issueMatrix } from "../../../base/risk/Risk";
import { useQ } from "../../../hooks/useQ";
import { project, programme, release, product } from "../../../queries";
import { useT } from "../../../hooks/useT";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { IssueStatusIcon } from "./IssueStatusIcon";
import { Trans } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { FormSelect } from "../../../base/form/mui/FormSelect";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { useToast } from "../../../hooks/useToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { v4 as uuidv4 } from "uuid";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";
import moment from "moment";
import { PDFKit, styles } from "../../../base/pdf";
import {
  Document,
  Page as PDFPage,
  PDFDownloadLink,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { DatePretty } from "../../../base/DatePretty";
import FlagIcon from "@mui/icons-material/Flag";
import { TitleWithHelper } from "../../../base/TitleWithHelper";
import DownloadIcon from "@mui/icons-material/Download";
import { IssuesBlockChart } from "@/pages/projects/components/IssuesBlockChart";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";
import { useBlocker, useNavigate } from "react-router-dom";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperFlow } from "@/base/form/mui/StepperFlow";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";
import { FormMultiSelect } from "@/base/form/mui/FormMultiSelect";
import { LessonDetails, LessonForm } from "@/pages/projects/Lessons";
import { useFlag } from "@/hooks/useFlag";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";
import { BlockerAlert } from "@/base/BlockerAlert";

const issueSteps = ["Capture", "Examine", "Propose", "Decide", "Implement"];

const issueTypeOptions = (t) => [
  {
    value: "change",
    label: t("project.change.issueRegister.fields.type.options.change"),
  },
  {
    value: "specification",
    label: t("project.change.issueRegister.fields.type.options.specification"),
  },
  {
    value: "problem",
    label: t("project.change.issueRegister.fields.type.options.problem"),
  },
];

const issueSeverityOptions = (t) => [
  {
    value: "5",
    label: t("project.change.issueRegister.fields.severity.options.5"),
  },
  {
    value: "4",
    label: t("project.change.issueRegister.fields.severity.options.4"),
  },
  {
    value: "3",
    label: t("project.change.issueRegister.fields.severity.options.3"),
  },
  {
    value: "2",
    label: t("project.change.issueRegister.fields.severity.options.2"),
  },
  {
    value: "1",
    label: t("project.change.issueRegister.fields.severity.options.1"),
  },
];

const issuePriorityOptions = (t) => [
  {
    value: "5",
    label: t("project.change.issueRegister.fields.priority.options.5"),
  },
  {
    value: "4",
    label: t("project.change.issueRegister.fields.priority.options.4"),
  },
  {
    value: "3",
    label: t("project.change.issueRegister.fields.priority.options.3"),
  },
  {
    value: "2",
    label: t("project.change.issueRegister.fields.priority.options.2"),
  },
  {
    value: "1",
    label: t("project.change.issueRegister.fields.priority.options.1"),
  },
];

const issueStatusOptions = (t) => [
  {
    value: "Active",
    label: t("project.change.issueRegister.fields.status.options.active"),
  },
  {
    value: "Closed",
    label: t("project.change.issueRegister.fields.status.options.closed"),
  },
];

let defaultPriority = [
  { row: 5, column: 5, classification: "high" }, //1
  { row: 4, column: 5, classification: "high" }, //2
  { row: 5, column: 4, classification: "high" }, //3
  { row: 3, column: 5, classification: "high" }, //4
  { row: 4, column: 4, classification: "high" }, //5
  { row: 5, column: 3, classification: "high" }, //6
  { row: 2, column: 5, classification: "high" }, //7
  { row: 3, column: 4, classification: "high" }, //8
  { row: 4, column: 3, classification: "medium" }, //9
  { row: 2, column: 4, classification: "medium" }, //10
  { row: 3, column: 3, classification: "medium" }, //11
  { row: 1, column: 5, classification: "medium" }, //12
  { row: 5, column: 2, classification: "medium" }, //13
  { row: 2, column: 3, classification: "medium" }, //14
  { row: 4, column: 2, classification: "medium" }, //15
  { row: 1, column: 4, classification: "medium" }, //16
  { row: 3, column: 2, classification: "medium" }, //17
  { row: 1, column: 3, classification: "low" }, //18
  { row: 2, column: 2, classification: "low" }, //19
  { row: 5, column: 1, classification: "low" }, //20
  { row: 4, column: 1, classification: "low" }, //21
  { row: 1, column: 2, classification: "low" }, //22
  { row: 3, column: 1, classification: "low" }, //23
  { row: 2, column: 1, classification: "low" }, //24
  { row: 1, column: 1, classification: "low" }, //25
  { row: undefined, column: undefined }, //26
];

const isLong = (text) => text?.length > 150;

export const IssuePdfReport = ({ isException, initiative, issue, date, t }) => {
  const { H1, H2, H3, H4, BODY, HR, HTML } = PDFKit;

  return (
    <Document>
      <PDFPage size="A4" style={styles.page}>
        <H1>
          {isException
            ? t("project.change.issueRegister.exceptionReport")
            : t("project.change.issueRegister.issueReport")}
        </H1>
        <H2>
          {initiative.name} -&nbsp;
          <DatePretty format={"ll"} date={date} />
        </H2>
        <View style={{ marginBottom: 10 }} />
        <H3 style={{ textAlign: "left" }}>{issue.title}</H3>
        <HTML>{issue.description}</HTML>
        <BODY>
          {t("project.change.issueRegister.fields.type.title")}
          {": "}
          {issueTypeOptions(t).find((i) => i.value === issue.type)?.label ??
            issue.type}
        </BODY>
        <View style={{ marginBottom: 10 }} />
        <HR />
        <H4>{t("project.change.issueRegister.fields.impact.title")}</H4>
        <HTML>{issue.impact}</HTML>
        <BODY>
          {t("project.change.issueRegister.fields.severity.title")}
          {": "}
          {issueSeverityOptions(t).find((i) => i.value === issue.severity)
            ?.label ?? issue.severity}{" "}
        </BODY>
        <View style={{ marginBottom: 10 }} />
        <BODY>
          {t("project.change.issueRegister.fields.priority.title")}
          {": "}
          {issuePriorityOptions(t).find((i) => i.value === issue.priority)
            ?.label ?? issue.priority}
        </BODY>
        <View style={{ marginBottom: 10 }} />
        <HR />
        <H4>{t("project.change.issueRegister.fields.options.title")}</H4>
        <HTML>{issue.options}</HTML>
        <HR />
        <H4>{t("project.change.issueRegister.fields.recommendation.title")}</H4>
        <HTML>{issue.recommendation}</HTML>
        <HR />
        <H4>{t("project.change.issueRegister.fields.decision.title")}</H4>
        <HTML>{issue.decision}</HTML>
        <HR />
        <H4>{t("project.change.issueRegister.fields.status.title")}</H4>
        <View style={{ marginBottom: 10 }} />
        <BODY>
          {issueStatusOptions(t).find((i) => i.value === issue.status)?.label ??
            issue.status}
        </BODY>
        <HTML>{issue.statusDescription}</HTML>
        {isException && (
          <>
            <HR />
            <H4>
              {t("project.change.issueRegister.fields.lessonSummary.title")}
            </H4>
            <HTML>{issue.lessonSummary ?? ""}</HTML>
          </>
        )}
      </PDFPage>
    </Document>
  );
};

const IssueReportDialog = ({
  open,
  type,
  onClose,
  date,
  issue,
  initiative,
  isException,
  lessons,
  onIssueCreated,
  readOnly = false,
  t,
}) => {
  const toast = useToast();
  const [viewType, setViewType] = useState("web");

  const createIssueReport = useMutation(
    type === "project"
      ? project.createIssueReport
      : type === "programme"
        ? programme.createIssueReport
        : release.createIssueReport,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success(
          isException
            ? t(
                "project.change.issueRegister.messages.success.createdException",
              )
            : t("project.change.issueRegister.messages.success.createdIssue"),
        );
        onIssueCreated?.();
        onClose();
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error(
          isException
            ? t("project.change.issueRegister.messages.error.creatingException")
            : t("project.change.issueRegister.messages.error.creatingIssue"),
        );
      },
    },
  );

  const handlePublish = useCallback(() => {
    createIssueReport.mutate({
      id: initiative.id,
      issueId: issue.id,
      data: {
        issue,
        exception: isException,
      },
    });
  }, [createIssueReport, issue, initiative]);

  const handleClose = useCallback(() => {
    setViewType("web");
    onClose();
  }, [onClose]);

  if (!issue || !initiative) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "800px",
        },
      }}
    >
      <DialogContent>
        <Typography variant="h1" textAlign="center">
          {isException
            ? t("project.change.issueRegister.exceptionReport")
            : t("project.change.issueRegister.issueReport")}
        </Typography>
        <Typography variant="h2" textAlign="center">
          {initiative.name} - {moment(date).format("YYYY-MM-DD")}
        </Typography>
        <Stack spacing={2} mt={2}>
          {!!readOnly && (
            <ButtonGroup fullWidth>
              <Button
                variant={viewType === "web" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setViewType("web")}
                disabled={createIssueReport.isLoading}
              >
                {t("generic.view.web")}
              </Button>
              <Button
                variant={viewType === "pdf" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setViewType("pdf")}
              >
                {t("generic.view.pdf")}
              </Button>
            </ButtonGroup>
          )}
          {viewType === "web" ? (
            <>
              <Typography variant="h2">{issue.title}</Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: issue.description }}
              />
              <Typography>
                {t("project.change.issueRegister.fields.type.title")}
                {": "}
                {issueTypeOptions(t).find((i) => i.value === issue.type)
                  ?.label ?? issue.type}
              </Typography>
              <Divider />
              <Typography variant="h3">
                {t("project.change.issueRegister.fields.impact.title")}
              </Typography>
              <Typography dangerouslySetInnerHTML={{ __html: issue.impact }} />
              <Typography>
                {t("project.change.issueRegister.fields.severity.title")}
                {": "}
                {issueSeverityOptions(t).find((i) => i.value === issue.severity)
                  ?.label ?? issue.severity}{" "}
              </Typography>
              <Typography>
                {t("project.change.issueRegister.fields.priority.title")}
                {": "}
                {issuePriorityOptions(t).find((i) => i.value === issue.priority)
                  ?.label ?? issue.priority}
              </Typography>
              <Divider />
              <Typography variant="h3">
                {t("project.change.issueRegister.fields.options.title")}
              </Typography>
              <Typography dangerouslySetInnerHTML={{ __html: issue.options }} />
              <Divider />
              <Typography variant="h3">
                {t("project.change.issueRegister.fields.recommendation.title")}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: issue.recommendation }}
              />
              <Divider />
              <Typography variant="h3">
                {t("project.change.issueRegister.fields.decision.title")}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: issue.decision }}
              />
              <Divider />
              <Typography variant="h3">
                {t("project.change.issueRegister.fields.status.title")}
              </Typography>
              <Typography>
                {issueStatusOptions(t).find((i) => i.value === issue.status)
                  ?.label ?? issue.status}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: issue.statusDescription }}
              />
              {isException && (
                <>
                  <Divider />
                  <Typography variant="h3">
                    {t(
                      "project.change.issueRegister.fields.lessonSummary.title",
                    )}
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: issue.lessonSummary }}
                  />
                  {(issue.lessons ?? []).length > 0 && (
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h3">
                        {t("project.change.issueRegister.fields.lessons.title")}
                      </Typography>

                      <Stack
                        direction="row"
                        sx={{ width: "100%", flexWrap: "wrap" }}
                        spacing={1}
                      >
                        {issue?.lessons.map((lessonId) => (
                          <RelatedLesson
                            lesson={lessons?.find(
                              (lesson) => lesson.id === lessonId,
                            )}
                            projectData={initiative}
                          />
                        ))}
                      </Stack>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <PDFDownloadLink
                document={
                  <IssuePdfReport
                    t={t}
                    initiative={initiative}
                    isException={isException}
                    issue={issue}
                    date={date}
                  />
                }
                fileName={`issueReport_${initiative?.name?.replace?.(
                  / /g,
                  "",
                )}_${moment(date).format("YYYY-MM-DD")}`}
              >
                <Button variant="contained" endIcon={<DownloadIcon />}>
                  Download PDF
                </Button>
              </PDFDownloadLink>
              <PDFViewer
                style={{
                  width: "100%",
                  height: "500px",
                }}
                showToolbar={false}
              >
                <IssuePdfReport
                  t={t}
                  initiative={initiative}
                  isException={isException}
                  issue={issue}
                  date={date}
                />
              </PDFViewer>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {!!readOnly ? t("generic.button.close") : t("generic.button.cancel")}
        </Button>
        {!readOnly && (
          <Button variant="contained" onClick={handlePublish}>
            {t(
              isException
                ? "project.change.issueRegister.buttons.exceptionReport"
                : "project.change.issueRegister.buttons.issueReport",
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const RelatedLesson = ({ lesson, projectData }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <Button variant="outlined" onClick={() => setShowDetails(!showDetails)}>
        <Typography>{lesson.title}</Typography>
      </Button>
      {showDetails && (
        <CustomDialog
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
        >
          <LessonDetails lesson={lesson} projectData={projectData} readOnly />
        </CustomDialog>
      )}
    </>
  );
};

export const IssueForm = ({
  projectId,
  projectData,
  issue,
  onSave,
  onClose,
  closeForm,
  t,
  isOpen,
  index,
  useScroll = true,
  lessons,
  refetchProjectLessons,
}) => {
  const isCreating = !issue;
  const nextStep = useRef(null);
  const useRestApiV1 = useFlag("lessons.useRestApiV1");
  const useIssueLessons = useFlag("lessons.issues");
  const [newLessonId, setNewLessonId] = useState(null);
  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const toast = useToast();

  const formData = useForm({
    defaultValues: {
      id: issue?.id ?? "",
      title: issue?.title ?? "",
      type: issue?.type ?? issueTypeOptions(t)[0].value,
      description: issue?.description ?? "",
      impact: issue?.impact ?? "",
      severity: issue?.severity ?? "",
      priority: issue?.priority ?? "",
      options: issue?.options ?? "",
      recommendation: issue?.recommendation ?? "",
      lessonSummary: issue?.lessonSummary ?? "",
      decision: issue?.decision ?? "",
      decidedBy: issue?.decidedBy ?? "",
      status: issue?.status ?? issueStatusOptions(t)[0].value,
      statusDescription: issue?.statusDescription ?? "",
      lessons: issue?.lessons ?? [],
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue,
  } = formData;
  const [
    title,
    type,
    description,
    impact,
    severity,
    priority,
    options,
    recommendation,
    lessonSummary,
    decision,
    decidedBy,
    lessonsFormData,
  ] = useWatch({
    control: formData.control,
    name: [
      "title",
      "type",
      "description",
      "impact",
      "severity",
      "priority",
      "options",
      "recommendation",
      "lessonSummary",
      "decision",
      "decidedBy",
      "lessons",
    ],
  });

  const blockChartIssues = useMemo(() => {
    if (issue) {
      return issueMatrix([
        {
          severity,
          priority,
          type: type,
          no: issue?.no ?? 0,
        },
      ]);
    } else {
      return issueMatrix([{}]);
    }
  }, [issue, severity, priority, type, index]);

  const handleChangePriorityAndSeverity = useCallback(
    (index) => {
      const severity = 1 + (index % 5);
      const priority = 5 - Math.floor(index / 5);
      setValue("severity", severity?.toString(), { shouldDirty: true });
      setValue("priority", priority?.toString(), { shouldDirty: true });
    },
    [severity, priority],
  );

  const initialDisabledSteps = useMemo(() => {
    if (isCreating) {
      return issueSteps.map((step, index) => index > 0);
    } else {
      const disabledSteps = issueSteps.map(() => true);
      const severity = issue?.severity ? Number(issue?.severity) : undefined;
      const priority = issue?.priority ? Number(issue?.priority) : undefined;
      disabledSteps[0] = false;
      if (issue?.title?.length && issue?.type && issue?.description?.length) {
        disabledSteps[1] = false;
        if (severity && priority && issue?.impact) {
          disabledSteps[2] = false;
          if (issue?.recommendation?.length) {
            disabledSteps[3] = false;
            if (issue?.decision?.length && issue?.decidedBy?.length) {
              disabledSteps[4] = false;
            }
          }
        }
      }
      return disabledSteps;
    }
  }, [isCreating, issue]);

  const [activeStep, setActiveStep] = useState(0);
  const [isStepDisabled, setIsStepDisabled] = useState([]);

  useEffect(() => {
    reset({
      id: issue?.id ?? "",
      title: issue?.title ?? "",
      type: issue?.type ?? issueTypeOptions(t)[0].value,
      description: issue?.description ?? "",
      lessons: issue?.lessons ?? [],
      impact: issue?.impact ?? "",
      severity: issue?.severity ?? "",
      priority: issue?.priority ?? "",
      options: issue?.options ?? "",
      recommendation: issue?.recommendation ?? "",
      lessonSummary: issue?.lessonSummary ?? "",
      decision: issue?.decision ?? "",
      decidedBy: issue?.decidedBy ?? "",
      status: issue?.status ?? issueStatusOptions(t)[0].value,
      statusDescription: issue?.statusDescription ?? "",
    });
    const title = issue?.title;
    const type = issue?.type;
    const description = issue?.description;
    const lessons = issue?.lessons;
    const impact = issue?.impact;
    const severity = issue?.severity ? Number(issue?.severity) : undefined;
    const priority = issue?.priority ? Number(issue?.priority) : undefined;
    const recommendation = issue?.recommendation;
    const decision = issue?.decision;
    const decidedBy = issue?.decidedBy;

    if (!title?.length || !type || !description?.length) {
      setActiveStep(0);
    } else if (!impact?.length || !severity || !priority) {
      setActiveStep(1);
    } else if (!recommendation?.length) {
      setActiveStep(2);
    } else if (!decision || !decidedBy) {
      setActiveStep(3);
    } else if (nextStep.current !== null) {
      setActiveStep(nextStep.current);
      nextStep.current = null;
    } else {
      setActiveStep(4);
    }
  }, [issue, reset]);

  useEffect(() => {
    if (isCreating) {
      setIsStepDisabled(issueSteps.map((step, index) => index > 0));
    } else {
      const disabledSteps = issueSteps.map(() => true);
      const severity = issue?.severity ? Number(issue?.severity) : undefined;
      const priority = issue?.priority ? Number(issue?.priority) : undefined;
      disabledSteps[0] = false;
      if (issue?.title?.length && issue?.type && issue?.description?.length) {
        disabledSteps[1] = false;
        if (issue?.impact?.length && severity && priority) {
          disabledSteps[2] = false;
          if (
            issue?.options?.length &&
            issue?.recommendation?.length &&
            issue?.lessonSummary?.length
          ) {
            disabledSteps[3] = false;
            if (issue?.decision?.length && issue?.decidedBy?.length) {
              disabledSteps[4] = false;
            }
          }
        }
      }
      setIsStepDisabled(disabledSteps);
    }
  }, [isCreating, issue]);

  useEffect(() => {
    if (!isCreating && isDirty) {
      const disabledSteps = [...isStepDisabled];
      disabledSteps[0] = false;
      disabledSteps[1] =
        initialDisabledSteps[1] ||
        !title?.length ||
        !type ||
        !description?.length;
      disabledSteps[2] =
        initialDisabledSteps[2] || !title?.length || !description?.length;
      !type || !impact || !severity || !priority;
      disabledSteps[3] =
        initialDisabledSteps[3] ||
        !title?.length ||
        !description?.length ||
        !type ||
        !impact ||
        !severity ||
        !priority ||
        !recommendation?.length;
      setIsStepDisabled(disabledSteps);
    } else {
      setIsStepDisabled(initialDisabledSteps);
    }
  }, [
    isCreating,
    isDirty,
    initialDisabledSteps,
    title,
    type,
    description,
    impact,
    severity,
    priority,
    options,
    recommendation,
    lessonSummary,
  ]);

  useEffect(() => {
    if (isOpen && useScroll) {
      setTimeout(function () {
        window.scrollTo({ top: 690 + 72 * index, behavior: "smooth" });
      }, 150);
    }
  }, [isOpen, issue]);

  useConfirmLeavingPage(isDirty);

  useEffect(() => {
    if (newLessonId) {
      if (!lessonsFormData.includes(newLessonId)) {
        setValue("lessons", [...lessonsFormData, newLessonId], {
          shouldDirty: true,
        });
      }
      setNewLessonId(null);
    }
  }, [lessonsFormData, lessons]);

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (isDirty) {
          await onSave(data);
          if (!issue) {
            reset();
          }
          if (!isStepDisabled[Math.min(4, activeStep + 1)]) {
            nextStep.current = Math.min(4, activeStep + 1);
          }
        } else {
          if (!isStepDisabled[activeStep + 1]) {
            setActiveStep((a) => a + 1);
          }
        }
      }),
    [handleSubmit, onSave, issue, reset, isDirty, isStepDisabled, activeStep],
  );

  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("project.change.issueRegister.buttons.create");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !title?.length || !description?.length
          ? false
          : t("generic.stepper.next");
      } else if (activeStep === 1) {
        return !impact || !severity || !priority
          ? false
          : t("generic.stepper.next");
      } else if (activeStep === 2) {
        return !recommendation ? false : t("generic.stepper.next");
      } else if (activeStep === 3) {
        return !decision || !decidedBy ? false : t("generic.stepper.next");
      }
      return false;
    } else {
      if (activeStep === 0) {
        return !title?.length || !description?.length
          ? t("project.change.issueRegister.buttons.update")
          : t("project.change.issueRegister.buttons.updateAndNext");
      } else if (activeStep === 1) {
        return !impact?.length || !severity || !priority
          ? t("project.change.issueRegister.buttons.update")
          : t("project.change.issueRegister.buttons.updateAndNext");
      } else if (activeStep === 2) {
        return !recommendation
          ? t("project.change.issueRegister.buttons.update")
          : t("project.change.issueRegister.buttons.updateAndNext");
      } else if (activeStep === 3) {
        return !decision || !decidedBy
          ? t("project.change.issueRegister.buttons.update")
          : t("project.change.issueRegister.buttons.updateAndNext");
      } else {
        return t("project.change.issueRegister.buttons.update");
      }
    }
  }, [
    isCreating,
    activeStep,
    isStepDisabled,
    title,
    impact,
    severity,
    priority,
    options,
    recommendation,
    lessonSummary,
    decision,
    decidedBy,
    isDirty,
  ]);

  const showPreviousButton = useMemo(() => {
    return !isCreating && activeStep > 0;
  }, [isCreating, activeStep]);

  const lessonsOptions = useMemo(
    () =>
      lessons.map((lesson) => ({
        id: lesson.id,
        value: lesson.title,
      })),
    [lessons],
  );

  const addLesson = useMutation(project.addLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson created successfully");
      setShowNewItemForm(false);
      refetchProjectLessons();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating lesson");
    },
  });

  const handleCreateLesson = useCallback(
    async (data) => {
      const lessonId = uuidv4();
      setNewLessonId(lessonId);
      await addLesson.mutate({
        useRestApiV1,
        projectId,
        lesson: {
          ...data,
          id: lessonId,
        },
      });
    },
    [projectId, useRestApiV1],
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && !!isDirty,
  );

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      scrollDependencyArray={[activeStep]}
    >
      <BlockerAlert blocker={blocker} />
      <FormDataProvider
        formKey="project.change.issueRegister.fields"
        {...formData}
      >
        <form onSubmit={triggerSubmit}>
          <Stack spacing={3}>
            <StepperFlow
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isStepDisabled={isStepDisabled}
              steps={issueSteps}
              formatLabel={(step) =>
                t("project.change.issueRegister.steps." + step?.toLowerCase())
              }
            />
            <Stack spacing={2}>
              {activeStep !== 0 && (
                <>
                  <Typography variant="h3" sx={{ fontWeight: "bold" }} flex={1}>
                    {issue?.no + ". " + issue?.title}{" "}
                    <Typography
                      flex={4}
                      sx={{
                        "& p": {
                          fontSize: "16px !important",
                        },
                      }}
                    >
                      (
                      {
                        issueTypeOptions(t).find(
                          (type) => type?.value === issue?.type,
                        ).label
                      }
                      )
                    </Typography>
                  </Typography>

                  <IssueDetailsColumn
                    title={t(
                      "project.change.issueRegister.fields.description.title",
                    )}
                    value={issue?.description}
                    isRich
                  />
                </>
              )}
              {activeStep !== 4 ? (
                <>
                  {activeStep > 1 && (
                    <IssueDetailsColumn
                      title={t(
                        "project.change.issueRegister.fields.impact.title",
                      )}
                      value={issue?.impact}
                      isRich
                    />
                  )}
                  {activeStep > 2 && (
                    <>
                      <IssueDetailsColumn
                        title={t(
                          "project.change.issueRegister.fields.options.title",
                        )}
                        value={issue?.options}
                        isRich
                      />
                      <IssueDetailsColumn
                        title={t(
                          "project.change.issueRegister.fields.recommendation.title",
                        )}
                        value={issue?.recommendation}
                        isRich
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <IssueDetailsColumn
                    title={t(
                      "project.change.issueRegister.fields.decision.title",
                    )}
                    value={issue?.decision}
                    isRich
                  />
                </>
              )}
            </Stack>
            {activeStep === 0 && (
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <FormTextField name="title" required />
                  <FormSelect
                    name="type"
                    options={issueTypeOptions(t)}
                    required
                  />
                </Stack>
                <FormRichTextField name="description" required />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <FormRichTextField name="impact" required />
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <Stack spacing={2} flex={1}>
                    <BlockChart
                      data={blockChartIssues}
                      yLabel={t("project.risksAndIssues.priority")}
                      xLabel={t("project.risksAndIssues.severity")}
                      onBlockSelected={handleChangePriorityAndSeverity}
                    />
                  </Stack>
                  <Stack spacing={2} flex={2}>
                    <FormSelect
                      name="severity"
                      options={issueSeverityOptions(t)}
                      required
                    />
                    <FormSelect
                      name="priority"
                      options={issuePriorityOptions(t)}
                      required
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                <FormRichTextField name="options" />
                <FormRichTextField name="recommendation" required />
                <FormRichTextField name="lessonSummary" />
                {useIssueLessons && (
                  <>
                    <FormMultiSelect
                      label=""
                      name="lessons"
                      options={lessonsOptions}
                    />
                    <Stack direction="row" justifyContent="flex-start">
                      <Button
                        variant="outlined"
                        endIcon={<AddCircleOutlineIcon />}
                        onClick={() => setShowNewItemForm(true)}
                      >
                        {t("project.plans.lessons.actions.create")}
                      </Button>
                    </Stack>
                  </>
                )}
              </Stack>
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>
                <FormRichTextField name="decision" required />
                <FormTextField name="decidedBy" required />
              </Stack>
            )}
            {activeStep === 4 && (
              <Stack spacing={2}>
                <FormSelect
                  name="status"
                  options={issueStatusOptions(t)}
                  required
                />
                <FormRichTextField name="statusDescription" required />
              </Stack>
            )}
            <StepperNavigator
              showPreviousButton={showPreviousButton}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </Stack>
        </form>
      </FormDataProvider>
      <LessonForm
        open={showNewItemForm}
        projectData={projectData}
        onCancel={() => setShowNewItemForm(false)}
        onSubmit={handleCreateLesson}
      />
    </CustomDialog>
  );
};

const IssueDetailsColumn = ({ title, value, isRich = false }) => {
  if (!value || value === "") {
    return null;
  }
  return (
    <Stack spacing={1}>
      <Stack direction="column" spacing={1} flex={1}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }} flex={1}>
          {title}
        </Typography>
        {!isRich ? (
          <Typography
            flex={4}
            sx={{
              "& p": {
                fontSize: "16px !important",
              },
            }}
          >
            {value}
          </Typography>
        ) : (
          <Typography
            flex={4}
            sx={{
              "& p": {
                fontSize: "16px !important",
              },
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        )}
      </Stack>
      {isLong(value) && <Divider />}
    </Stack>
  );
};

const IssueDetailsRow = ({ title, value, isRich = false }) => {
  if (!value || value === "") {
    return null;
  }
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} flex={1}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }} flex={1}>
          {title}
        </Typography>
        {!isRich ? (
          <Typography
            flex={4}
            sx={{
              "& p": {
                fontSize: "16px !important",
              },
            }}
          >
            {value}
          </Typography>
        ) : (
          <Typography
            flex={4}
            sx={{
              "& p": {
                fontSize: "16px !important",
              },
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        )}
      </Stack>
      {isLong(value) && <Divider />}
    </Stack>
  );
};

export const IssueDetails = ({
  type,
  initiative,
  issue,
  onEdit,
  onIssueCreated,
  id,
  isOpen,
  lessons = [],
}) => {
  const { t } = useT();
  const threats = useMemo(() => issueMatrix([issue]), [issue]);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isException, setIsException] = useState(false);

  const fields = [
    "impact",
    "options",
    "recommendation",
    "lessonSummary",
    "decision",
    "statusDescription",
  ];

  const myRef = useRef(null);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });

  useEffect(() => {
    if (isOpen) {
      setTimeout(function () {
        executeScroll();
      }, 150);
    }
  }, [isOpen, issue]);

  return (
    <Stack spacing={3}>
      <Box ref={myRef} />
      {!!issue?.reports?.length && (
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.change.issueRegister.reports")}
          </Typography>
          <Divider />
          {issue?.reports?.map((report) => (
            <Stack
              key={report.id}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Button
                onClick={() => {
                  setSelectedReport(report);
                  setIsReportDialogOpen(true);
                }}
                variant="text"
                endIcon={report.exception ? <FlagIcon /> : undefined}
              >
                {moment(report.date).format("YYYY-MM-DD")}
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack spacing={1}>
        <Typography variant="h2">
          {t("project.change.issueRegister.resources")}
        </Typography>
        <Divider />
      </Stack>
      {!issue?.readOnly && (
        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button
            variant="text"
            color="secondary"
            startIcon={<EditOutlined />}
            onClick={() => onEdit(issue)}
          >
            {t("generic.button.edit")}
          </Button>
        </Stack>
      )}
      <Stack direction="row" flex={1} alignItems="center">
        <Stack flex={1}>
          <IssuesBlockChart id={id} issues={threats} type={type} />
        </Stack>
        <Stack
          flex={fields.some((field) => isLong(issue?.[field])) ? 2 : 1}
          spacing={1}
        >
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.impact.title")}
            value={issue?.impact}
            isRich
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.severity.title")}
            value={issue?.severity}
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.priority.title")}
            value={issue?.priority}
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.options.title")}
            value={issue?.options}
            isRich
          />
          <IssueDetailsRow
            title={t(
              "project.change.issueRegister.fields.recommendation.title",
            )}
            value={issue?.recommendation}
            isRich
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.lessonSummary.title")}
            value={issue?.lessonSummary}
            isRich
          />
          {(issue.lessons ?? []).length > 0 && (
            <Box>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {t("project.change.issueRegister.fields.lessons.title")}
              </Typography>
              <Typography>
                {issue.lessons
                  .map(
                    (lessonId) =>
                      lessons.find((lesson) => lesson.id === lessonId)?.title,
                  )
                  .join(", ")}
              </Typography>
            </Box>
          )}
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.decision.title")}
            value={issue?.decision}
            isRich
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.decidedBy.title")}
            value={issue.decidedBy}
          />
          <IssueDetailsRow
            title={t("project.change.issueRegister.fields.status.title")}
            value={
              issueStatusOptions(t).find((o) => o.value === issue.status)?.label
            }
            isRich
          />
          <IssueDetailsRow
            title={t(
              "project.change.issueRegister.fields.statusDescription.title",
            )}
            value={issue?.statusDescription}
            isRich
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Box>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            {issue.no}. {issue.title}
          </Typography>
          <Typography>
            ({issueTypeOptions(t).find((o) => o.value === issue.type)?.label})
          </Typography>
        </Box>
        {issue.description && issue.description !== "" && (
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {t("project.change.issueRegister.fields.description.title")}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: issue.description }}
            />
          </Box>
        )}
      </Stack>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsReportDialogOpen(true);
              setIsException(false);
            }}
          >
            {t("project.change.issueRegister.buttons.issueReport")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsReportDialogOpen(true);
              setIsException(true);
            }}
          >
            {t("project.change.issueRegister.buttons.exceptionReport")}
          </Button>
        </Stack>
        <TitleWithHelper
          title={t("project.change.issueRegister.whenToUseReports.title")}
          help={t("project.change.issueRegister.whenToUseReports.help")}
        />
      </Stack>
      <IssueReportDialog
        t={t}
        open={isReportDialogOpen}
        onClose={() => {
          setIsReportDialogOpen(false);
          setSelectedReport(null);
        }}
        issue={selectedReport ? selectedReport.issue : issue}
        date={selectedReport ? selectedReport.date : issue.statusUpdated}
        initiative={initiative}
        type={type}
        isException={selectedReport ? selectedReport.exception : isException}
        readOnly={!!selectedReport}
        lessons={lessons}
        onIssueCreated={onIssueCreated}
      />
    </Stack>
  );
};

export const IssueRegister = ({
  projectId,
  issueId,
  programmeId,
  releaseId,
  productId,
  type,
}) => {
  const { t } = useT();

  const { data: projectData } = useQ(
    `project-${projectId}`,
    () => project.single({ id: projectId }),
    { enabled: type === "project" },
  );
  const { data: programmeData } = useQ(
    `programme-${programmeId}`,
    () => programme.single({ id: programmeId }),
    { enabled: type === "programme" },
  );
  const { data: releaseData } = useQ(
    `release-${releaseId}`,
    () => release.single({ id: releaseId }),
    { enabled: type === "release" },
  );
  const { data: productData } = useQ(
    `product-${productId}`,
    () => product.single({ id: productId }),
    { enabled: type === "product" },
  );
  const {
    data: issues = [],
    refetch: refetchIssues,
    isLoading,
  } = useQ(
    `project-${projectId}-issues`,
    () => project.issues({ id: projectId }),
    { enabled: type === "project" },
  );
  const {
    data: programmeIssues = [],
    refetch: refetchProgrammeIssues,
    isLoadingProgramme,
  } = useQ(
    `programme-${programmeId}-issues`,
    () => programme.issues({ id: programmeId }),
    { enabled: type === "programme" },
  );
  const {
    data: releaseIssues = [],
    refetch: refetchReleaseIssues,
    isLoadingRelease,
  } = useQ(
    `release-${releaseId}-issues`,
    () => release.issues({ id: releaseId }),
    { enabled: type === "release" },
  );
  const {
    data: productIssues = [],
    refetch: refetchProductIssues,
    isLoadingProduct,
  } = useQ(
    `product-${productId}-issues`,
    () => product.issues({ id: productId }),
    { enabled: type === "product" },
  );
  const matrixIssues = useMemo(
    () =>
      issueMatrix(
        type === "project"
          ? issues
          : type === "programme"
            ? programmeIssues
            : type === "release"
              ? releaseIssues
              : productIssues,
      ),
    [
      type === "project"
        ? issues
        : type === "programme"
          ? programmeIssues
          : type === "release"
            ? releaseIssues
            : productIssues,
    ],
  );

  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const recentReports = useMemo(() => {
    const allIssues =
      type === "project"
        ? issues
        : type === "programme"
          ? programmeIssues
          : type === "release"
            ? releaseIssues
            : productIssues;
    const allReports = allIssues.flatMap((i) => i.reports);
    const sortedReports = allReports.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return sortedReports.slice(0, 8);
  }, [type, issues, programmeIssues, releaseIssues, productIssues]);

  const { data: projectLessonsData = {}, refetch: refetchProjectLessons } =
    useQ(`project-lessons`, () => project.lessons());

  const lessons = projectLessonsData.lessons ?? [];

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={8}
      >
        <Stack spacing={2} flex={1}>
          <Typography variant="h1">
            {t("project.navigation.change.issueRegister")}
          </Typography>
          <Typography variant="h3">
            <Trans
              i18nKey="project.change.issueRegister.helperText"
              components={{ br: <br /> }}
            />
          </Typography>
        </Stack>
        <Box sx={{ mt: 3, width: "270px" }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            {t("project.change.issueRegister.recentReports")}
          </Typography>
          <Stack alignItems="flex-start" spacing={1}>
            {recentReports.map((report) =>
              report?.date ? (
                <Button
                  key={report.id}
                  variant="text"
                  onClick={() => {
                    setSelectedReport(report);
                    setIsReportDialogOpen(true);
                  }}
                  endIcon={report.exception ? <FlagIcon /> : undefined}
                  sx={{
                    whiteSpace: "normal",
                    minHeight: "38px",
                    height: "initial",
                    textAlign: "left",
                    padding: 0,
                  }}
                >
                  {`[${moment(report.date).format("YYYY-MM-DD")}] ${
                    report.issue.no
                  }. ${report.issue.title}`}
                </Button>
              ) : null,
            )}
          </Stack>
        </Box>
        <IssuesBlockChart
          id={
            type === "project"
              ? projectId
              : type === "programme"
                ? programmeId
                : type === "release"
                  ? releaseId
                  : productId
          }
          issues={matrixIssues}
          type={type}
        />
      </Stack>
      <IssueTable
        lessons={lessons}
        refetchProjectLessons={refetchProjectLessons}
        initiativeData={
          type === "project"
            ? projectData
            : type === "programme"
              ? programmeData
              : type === "release"
                ? releaseData
                : productData
        }
        issues={
          type === "project"
            ? issues
            : type === "programme"
              ? programmeIssues
              : type === "release"
                ? releaseIssues
                : productIssues
        }
        refetchIssues={
          type === "project"
            ? refetchIssues
            : type === "programme"
              ? refetchProgrammeIssues
              : type === "release"
                ? refetchReleaseIssues
                : refetchProductIssues
        }
        id={
          type === "project"
            ? projectId
            : type === "programme"
              ? programmeId
              : type === "release"
                ? releaseId
                : productId
        }
        issueId={issueId}
        type={type}
        isLoading={isLoading}
      />
      <IssueReportDialog
        t={t}
        open={isReportDialogOpen}
        onClose={() => {
          setIsReportDialogOpen(false);
          setSelectedReport(null);
        }}
        issue={selectedReport?.issue}
        date={selectedReport?.date}
        initiative={
          type === "project"
            ? projectData
            : type === "programme"
              ? programmeData
              : type === "release"
                ? releaseData
                : productData
        }
        type={type}
        isException={selectedReport?.exception}
        readOnly={!!selectedReport}
        lessons={lessons}
      />
    </>
  );
};

export const IssueTable = ({
  initiativeData,
  issues,
  refetchIssues,
  id,
  issueId,
  type,
  isLoading,
  navigation = true,
  viewOnly,
  lessons = [],
  refetchProjectLessons,
}) => {
  const { t } = useT();
  const navigate = useNavigate();

  const toast = useToast();
  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [isEditingIssue, setIsEditingIssue] = useState({});
  const [highlightedIssue, highlightIssue] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hiddenIssues, setHiddenIssues] = useState([]);
  const [nextId, setNextId] = useState(uuidv4());

  const matrixIssues = useMemo(
    () => issueMatrix([...hiddenIssues, ...issues]),
    [hiddenIssues, issues],
  );

  useEffect(() => {
    setIsEditingIssue((prev) =>
      Object.fromEntries(issues.map((i) => [i.id, prev[i.id] ?? false])),
    );
    if (issues.map((i) => i.id).includes(editId)) {
      setHiddenIssues([]);
    }
  }, [issues]);

  const createIssue = useMutation(
    type === "project"
      ? project.createIssue
      : type === "programme"
        ? programme.createIssue
        : type === "release"
          ? release.createIssue
          : product.createIssue,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success(
          t("project.change.issueRegister.messages.success.createdIssue"),
        );
        refetchIssues();
        setShowNewItemForm(false);
        handleOpenIssue(nextId);
        highlightIssue(nextId);
        setIsEditingIssue((prev) => ({
          ...prev,
          [nextId]: true,
        }));
        if (
          !navigation &&
          !issues
            .map((i) => i.id)
            .includes(data.id.items[data.id.items.length - 1].id)
        ) {
          setHiddenIssues([data.id.items[data.id.items.length - 1]]);
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error(
          t("project.change.issueRegister.messages.error.creatingIssue"),
        );
      },
    },
  );

  const editIssue = useMutation(
    type === "project"
      ? project.editIssue
      : type === "programme"
        ? programme.editIssue
        : type === "release"
          ? release.editIssue
          : product.editIssue,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success(
          t("project.change.issueRegister.messages.success.updatedIssue"),
        );
        refetchIssues();
        if (!navigation && !issues.map((i) => i.id).includes(editId)) {
          setHiddenIssues(data?.id?.items?.filter((i) => i.id === editId));
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error(
          t("project.change.issueRegister.messages.error.updatingIssue"),
        );
      },
    },
  );

  const handleOpenIssue = useCallback(
    (newIssueId) => {
      if (!viewOnly) {
        if (navigation) {
          navigate(
            clientUrl(
              uris[type][newIssueId === issueId ? "change" : "changeDetails"],
              {
                id: id,
                issueId: newIssueId === issueId ? "" : newIssueId,
              },
            ),
          );
        } else {
          setEditId(newIssueId);
        }
      }
    },
    [navigate, type, id, viewOnly],
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  const statusValues = {
    undefined: 0,
    success: 1,
    warn: 2,
    danger: 3,
  };

  const tableIssues = useMemo(
    () =>
      [...issues, ...hiddenIssues]
        .map((issue) => ({
          ...issue,
          riskColor: matrixIssues
            .flat()
            .find((threat) =>
              threat.cellItems.find((cell) => cell.no === issue.no),
            )?.status,
        }))
        .sort((i1, i2) =>
          i1.closed !== i2.closed
            ? !!i1.closed
              ? 1
              : -1
            : i1.riskColor === i2.riskColor
              ? i2.impact === i1.impact
                ? i2.probability - i1.probability
                : i2.impact - i1.impact
              : statusValues[i2.riskColor] - statusValues[i1.riskColor],
        ),
    [issues, hiddenIssues, matrixIssues],
  );

  return (
    <>
      <Box sx={{ mt: 3 }}>
        {!viewOnly && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            mb={1}
          >
            <Button
              variant="text"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setShowNewItemForm((s) => !s)}
            >
              {showNewItemForm
                ? t("generic.button.close")
                : t("project.change.issueRegister.buttons.add")}
            </Button>
          </Stack>
        )}
        {[...issues, ...hiddenIssues].length > 0 || showNewItemForm ? (
          <CustomAccordion
            tableView
            rowSx={{
              alignItems: "flex-start",
            }}
            header={{
              expanded: false,
              columns: [
                {
                  title: "",
                  width: 20,
                },
                {
                  title: "ID",
                  flex: 1,
                },
                {
                  title: t("project.change.issueRegister.fields.title.title"),
                  flex: 2,
                },
                {
                  title: t("project.change.issueRegister.fields.impact.title"),
                  flex: 2,
                },
                {
                  title: t(
                    "project.change.issueRegister.fields.recommendation.title",
                  ),
                  flex: 2,
                },
                {
                  title: t(
                    "project.change.issueRegister.fields.decision.title",
                  ),
                  flex: 2,
                },
                {
                  title: t("project.change.issueRegister.fields.status.title"),
                  flex: 2,
                },
              ],
              details: showNewItemForm && (
                <IssueForm
                  projectData={initiativeData}
                  t={t}
                  onClose={() => setShowNewItemForm(false)}
                  onSave={(data) => {
                    setNextId(uuidv4());
                    createIssue.mutate({
                      id: id,
                      programme: { id: type === "programme" ? id : undefined },
                      data: {
                        ...data,
                        id: nextId,
                      },
                    });
                  }}
                  isOpen={showNewItemForm}
                  useScroll={navigation}
                  lessons={lessons}
                  refetchProjectLessons={refetchProjectLessons}
                />
              ),
            }}
            rows={tableIssues.map((issue, index) => {
              let status = "";
              switch (issue._progress) {
                case "Capture":
                  status = t("project.change.issueRegister.status.capture");
                  break;
                case "Examine":
                  status = t("project.change.issueRegister.status.examined");
                  break;
                case "Propose":
                  status = t("project.change.issueRegister.status.proposed");
                  break;
                case "Decide":
                  status = t("project.change.issueRegister.status.decided");
                  break;
                case "Implement":
                  status = t("project.change.issueRegister.status.implemented");
                  break;
              }
              return {
                id: issue.id,
                expanded: issue.id === issueId,
                expandDisabled: !!viewOnly,
                onClick: () => handleOpenIssue(issue.id),
                columns: [
                  {
                    content: (
                      <Stack alignItems="center" justifyContent="center">
                        <IssueStatusIcon
                          status={
                            matrixIssues
                              .flat()
                              .find((issue2) =>
                                issue2.cellItems.find(
                                  (i) => i.id === issue?.id,
                                ),
                              )?.status
                          }
                        />
                      </Stack>
                    ),
                    width: 20,
                  },
                  {
                    title: issue.no,
                    flex: 1,
                  },
                  {
                    title: issue.title,
                    flex: 2,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: isLong(issue.impact)
                            ? issue.impact.slice(0, 145) + "..."
                            : issue.impact,
                        }}
                      />
                    ),
                    flex: 2,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: isLong(issue.recommendation)
                            ? issue.recommendation.slice(0, 145) + "..."
                            : issue.recommendation,
                        }}
                      />
                    ),
                    flex: 2,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: isLong(issue.decision)
                            ? issue.decision.slice(0, 145) + "..."
                            : issue.decision,
                        }}
                      />
                    ),
                    flex: 2,
                  },
                  {
                    content: (
                      <Stack>
                        <Typography>{status}</Typography>
                        {issue._progress === "Implement" &&
                        issue.statusUpdated ? (
                          <Stack sx={{ mt: 1 }}>
                            <Typography fontWeight="bold">
                              {new Date(issue.statusUpdated)
                                .toISOString()
                                .slice(0, 10) + ":"}
                            </Typography>
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: issue.statusDescription,
                              }}
                            />
                          </Stack>
                        ) : null}
                      </Stack>
                    ),
                    flex: 2,
                  },
                ],
                sx: {
                  "& > .MuiAccordionSummary-root *": {
                    textDecoration:
                      issue.status === "Closed"
                        ? "line-through !important"
                        : "none",
                    color:
                      issue.status === "Closed"
                        ? "rgb(180,180,180) !important"
                        : "inherit",
                  },
                },
                details: (
                  <>
                    <IssueDetails
                      index={index}
                      isOpen={issue.id === issueId}
                      issue={issue}
                      type={type}
                      initiative={initiativeData}
                      onEdit={() =>
                        setIsEditingIssue((prev) => ({
                          ...prev,
                          [issue.id]: true,
                        }))
                      }
                      onIssueCreated={() => {
                        refetchIssues();
                      }}
                      id={id}
                      lessons={lessons}
                    />
                    {((issueId === issue.id && isEditingIssue[issue.id]) ||
                      editId === issue.id) && (
                      <IssueForm
                        t={t}
                        issue={issue}
                        projectId={id}
                        projectData={initiativeData}
                        onSave={(data) =>
                          editIssue.mutate({
                            id: id,
                            issueId: issue?.id,
                            data: {
                              ...issue,
                              ...data,
                            },
                          })
                        }
                        onClose={() => {
                          setIsEditingIssue((prev) => ({
                            ...prev,
                            [issue.id]: false,
                          }));
                          if (!navigation) {
                            setEditId(null);
                          }
                        }}
                        closeForm={() => handleOpenIssue(issue.id)}
                        isHighlighted={highlightedIssue === issue.id}
                        setIsHighlighted={() => highlightIssue("")}
                        isOpen={
                          (issueId === issue.id && isEditingIssue[issue.id]) ||
                          editId === issue.id
                        }
                        index={index}
                        useScroll={navigation}
                        lessons={lessons}
                        refetchProjectLessons={refetchProjectLessons}
                      />
                    )}
                  </>
                ),
              };
            })}
          />
        ) : (
          <Typography
            variant="h3"
            sx={{ color: "#6B7280", fontWeight: "bold", mt: 4 }}
          >
            {t("project.change.noIssues")}
          </Typography>
        )}
      </Box>
    </>
  );
};
