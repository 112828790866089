import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FormRichTextField } from "@/base/form/mui/FormRichTextField";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { useFlag } from "@/hooks/useFlag";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { CustomAccordion } from "@/layout/CustomAccordion";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { v4 as uuidV4 } from "uuid";
import { useMutation } from "react-query";
import { project } from "@/queries";
import { useToast } from "@/hooks/useToast";
import { useQ } from "@/hooks/useQ";
import { DisplayContent } from "@/base/content/DisplayContent";
import { EditOutlined } from "@mui/icons-material";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperSections } from "@/base/form/mui/StepperSections";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { BenefitDetails } from "../programmes/Design/BenefitsForm";
import { DeleteButton } from "@/base/DeleteButton";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";

const BenefitsForm = ({
  index,
  benefit,
  enableSingleUpdate,
  handleShowEditItemForm,
  editBenefit,
  deleteBenefit,
  setValue,
  isOpen,
  resetField,
}) => {
  const { t } = useT();
  const initialBenefit = useRef(benefit);

  const handleClose = useCallback(() => {
    if (benefit.isNew) {
      deleteBenefit(index);
    } else {
      resetField(`benefits[${index}]`);
      handleShowEditItemForm(index, null);
    }
  }, []);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (benefit !== initialBenefit.current) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [benefit]);

  useConfirmLeavingPage(isDirty);

  return (
    <CustomDialog isOpen={isOpen} onClose={handleClose}>
      <Stack spacing={2} direction="column">
        <Stack spacing={2} direction="row">
          <FormTextField
            name={`benefits[${index}].title`}
            fieldKey="benefits.title"
            help={false}
            fullWidth
            viewOnly={true}
          />
          <FormTextField
            name={`benefits[${index}].accountable`}
            fieldKey="benefits.accountable"
            fullWidth
          />
        </Stack>
        <FormRichTextField
          name={`benefits[${index}].measurement`}
          fieldKey="benefits.measurement"
        />
        <FormRichTextField
          name={`benefits[${index}].actions`}
          fieldKey="benefits.actions"
          fullWidth
        />
        <Stack spacing={2} direction="row">
          <FormTextField
            name={`benefits[${index}].baseline`}
            fieldKey="benefits.baseline"
            fullWidth
          />
          <FormTextField
            name={`benefits[${index}].goal`}
            fieldKey="benefits.goal"
            fullWidth
          />
        </Stack>
        {enableSingleUpdate ? (
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {isDirty
                ? t("programme.editDesignForm.benefits.cancel")
                : t("generic.button.close")}
            </Button>
            {isDirty && (
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  handleShowEditItemForm(index, null);
                  setValue(`benefits[${index}].isNew`, null, {
                    shouldDirty: false,
                  });
                }}
              >
                {benefit.isNew
                  ? t("programme.editDesignForm.benefits.save")
                  : t("programme.editDesignForm.benefits.update")}
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => handleShowEditItemForm(index, null)}
            >
              {t("generic.button.close")}
            </Button>
          </Stack>
        )}
      </Stack>
    </CustomDialog>
  );
};
export const BenefitsTable = ({
  benefits,
  handleShowEditItemForm,
  handleShowItem,
  editItemIds,
  showItemIds,
  deleteBenefit,
  editBenefit,
  readOnly = false,
  enableSingleUpdate = false,
  setValue,
  resetField,
}) => {
  const { t } = useT();

  return (
    <CustomAccordion
      tableView
      header={{
        expanded: false,
        columns: [
          {
            title: "ID",
            flex: 1,
            sx: { marginRight: -2 },
          },
          {
            title: t(
              "project.businessCase.addEditForm.fields.benefits.title.title",
            ),
            flex: 2,
          },
          {
            title: t(
              "project.businessCase.addEditForm.fields.benefits.baseline.title",
            ),
            flex: 3,
          },
          {
            title: t(
              "project.businessCase.addEditForm.fields.benefits.goal.title",
            ),
            flex: 4,
          },
          {
            title: t(
              "project.businessCase.addEditForm.fields.benefits.accountable.title",
            ),
            flex: 5,
          },
        ],
      }}
      rows={[
        ...(benefits ?? []).map((item, index) => ({
          id: item.id,
          expanded: !readOnly && showItemIds[index] === item.id,
          expandDisabled: !!readOnly,
          onClick: () => {
            if (!readOnly) {
              showItemIds[index] !== item.id
                ? handleShowItem(index, item.id)
                : handleShowItem(index, null);
            }
          },
          columns: [
            {
              content: <Typography sx={{ mr: 1 }}>{index + 1}</Typography>,
              width: "50px",
            },
            {
              content: <Typography>{item.title}</Typography>,
              flex: 2,
              sx: { marginRight: 10 },
            },
            {
              content: <Typography>{item.baseline}</Typography>,
              flex: 3,
            },
            {
              content: <Typography>{item.goal}</Typography>,
              flex: 4,
            },
            {
              content: <Typography>{item.accountable}</Typography>,
              flex: 5,
              sx: { marginLeft: 5 },
            },
          ],
          details: readOnly ? null : (
            <Stack>
              <Stack justifyContent="flex-end" direction="row" spacing={2}>
                <DeleteButton
                  onConfirm={() => deleteBenefit(index)}
                  content="Are you sure you want to delete this benefit?"
                />
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<EditOutlined />}
                  onClick={() => handleShowEditItemForm(index, item.id)}
                >
                  {t("generic.button.edit")}
                </Button>
              </Stack>
              <BenefitDetails item={item} index={index} t={t} />
              {editItemIds[index] === item.id && (
                <BenefitsForm
                  index={index}
                  benefit={item}
                  enableSingleUpdate={enableSingleUpdate}
                  handleShowEditItemForm={handleShowEditItemForm}
                  editBenefit={editBenefit}
                  deleteBenefit={deleteBenefit}
                  setValue={setValue}
                  isOpen={!readOnly && editItemIds[index] === item.id}
                  resetField={resetField}
                />
              )}
            </Stack>
          ),
        })),
      ]}
    />
  );
};

export const BusinessCase = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const toast = useToast();

  const { data: businessCaseData = {}, refetch } = useQ(
    `project-${id}-businessCase`,
    () => project.businessCase({ id }),
  );

  const [showItemIds, setShowItemIds] = useState([].map(() => null));
  const [editItemIds, setEditItemIds] = useState([].map(() => null));
  const [editBusinessCase, setEditBusinessCase] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const schema = yup.object().shape({
    reasons: yup.string().optional(),
    doNothing: yup.string().optional(),
    doMinimal: yup.string().optional(),
    doSomething: yup.string().optional(),
    projectPlanSummary: yup.string().optional(),
    investmentAppraisal: yup.string().optional(),
    risks: yup.string().optional(),
    executiveSummary: yup.string().optional(),
    benefits: yup.array().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reasons: "",
      doNothing: "",
      doMinimal: "",
      doSomething: "",
      projectPlanSummary: "",
      investmentAppraisal: "",
      risks: "",
      executiveSummary: "",
      benefits: [],
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    resetField,
    formState: { isDirty },
  } = formData;

  useEffect(() => {
    reset({
      reasons: businessCaseData.reasons ?? "",
      doNothing: businessCaseData.doNothing ?? "",
      doMinimal: businessCaseData.doMinimal ?? "",
      doSomething: businessCaseData.doSomething ?? "",
      projectPlanSummary: businessCaseData.projectPlanSummary ?? "",
      investmentAppraisal: businessCaseData.investmentAppraisal ?? "",
      risks: businessCaseData.risks ?? "",
      executiveSummary: businessCaseData.executiveSummary ?? "",
      benefits: businessCaseData.benefits ?? [],
    });
    benefitRef.current = businessCaseData.benefits ?? [];
    if (showItemIds.length < businessCaseData.benefits?.length) {
      setShowItemIds(businessCaseData.benefits.map(() => null));
      setEditItemIds(businessCaseData.benefits.map(() => null));
    }
  }, [reset, businessCaseData, editBusinessCase]);

  const [benefits] = useWatch({
    control,
    name: ["benefits"],
  });
  const benefitRef = useRef(benefits);
  benefitRef.current = benefits;

  const deleteBenefit = useCallback(
    (index) => {
      const newBenefit = [...benefitRef.current];
      newBenefit.splice(index, 1);
      setValue("benefits", newBenefit, { shouldDirty: true });
    },
    [setValue],
  );

  const handleShowEditItemForm = useCallback((index, itemId) => {
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      itemId,
      ...editItemIds.slice(index + 1),
    ]);
  }, []);

  const handleShowItem = useCallback((index, itemId) => {
    setShowItemIds((showItemIds) => [
      ...showItemIds.slice(0, index),
      itemId,
      ...showItemIds.slice(index + 1),
    ]);
  }, []);

  const addNewBenefit = useCallback(() => {
    let id = uuidV4();
    let newBenefits = [
      ...benefitRef.current,
      {
        id: id,
        accountable: "",
        actions: "",
        baseline: "",
        goal: "",
        measurement: "",
        title: "",
        isNew: true,
      },
    ];
    setValue("benefits", newBenefits, { shouldDirty: true });
    handleShowEditItemForm(newBenefits.length - 1, id);
  }, [setValue]);

  const updateBusinessCase = useMutation(project.updateBusinessCase, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Business case updated successfully");
      refetch();
      reset(data);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating business case");
    },
  });

  const useBusinessCase = useFlag("businessCase.home", id) || true;
  if (!useBusinessCase) {
    window.location.href = "/external/projects/" + id + "/businessCase";
    return null;
  }

  const submitButtonLabel = useMemo(() => {
    if (Object.keys(businessCaseData).length === 0) {
      return t("project.businessCase.addEditForm.create");
    }
    if (!isDirty) {
      if (activeStep === 4) {
        return false;
      }
      return t("generic.stepper.next");
    } else {
      if (activeStep === 4) {
        return t("project.businessCase.addEditForm.update");
      } else {
        return t("project.businessCase.addEditForm.updateAndNext");
      }
    }
  }, [isDirty, activeStep, businessCaseData]);

  const handleClose = () => {
    setEditBusinessCase(false);
    reset({
      reasons: businessCaseData.reasons ?? "",
      doNothing: businessCaseData.doNothing ?? "",
      doMinimal: businessCaseData.doMinimal ?? "",
      doSomething: businessCaseData.doSomething ?? "",
      projectPlanSummary: businessCaseData.projectPlanSummary ?? "",
      investmentAppraisal: businessCaseData.investmentAppraisal ?? "",
      risks: businessCaseData.risks ?? "",
      executiveSummary: businessCaseData.executiveSummary ?? "",
      benefits: businessCaseData.benefits ?? [],
    });
    benefitRef.current = businessCaseData.benefits ?? [];
  };
  return (
    <Page isLoading={false} title={t("project.businessCase.title")}>
      <Section title={t("project.businessCase.title")}>
        {editBusinessCase && (
          <CustomDialog
            isOpen={editBusinessCase}
            onClose={handleClose}
            scrollDependencyArray={[activeStep]}
          >
            <StepperSections
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={[
                "strategyAndObjectives",
                "options",
                "benefits",
                "roi",
                "summary",
              ]}
              formatLabel={(step) =>
                t(`project.businessCase.addEditForm.steps.${step}`)
              }
            />
            <FormDataProvider
              formKey="project.businessCase.addEditForm.fields"
              {...formData}
            >
              <form
                onSubmit={handleSubmit((data) => {
                  if (isDirty) {
                    updateBusinessCase.mutate({
                      id,
                      data,
                    });
                  }
                  setActiveStep((prevActiveStep) =>
                    Math.min(prevActiveStep + 1, 4),
                  );
                })}
              >
                <Box sx={{ mt: 4 }}>
                  {activeStep === 0 && (
                    <Stack spacing={2}>
                      <FormRichTextField name="reasons" />
                    </Stack>
                  )}
                  {activeStep === 1 && (
                    <Stack spacing={2}>
                      <FormRichTextField name="doNothing" />
                      <FormRichTextField name="doMinimal" />
                      <FormRichTextField name="doSomething" />
                    </Stack>
                  )}
                  {activeStep === 2 && (
                    <Stack spacing={2}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Button
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          onClick={addNewBenefit}
                        >
                          {t("project.businessCase.addEditForm.addEntry")}
                        </Button>
                      </Stack>
                      <BenefitsTable
                        benefits={benefits}
                        handleShowEditItemForm={handleShowEditItemForm}
                        handleShowItem={handleShowItem}
                        editItemIds={editItemIds}
                        showItemIds={showItemIds}
                        deleteBenefit={deleteBenefit}
                        enableSingleUpdate={true}
                        setValue={setValue}
                        resetField={resetField}
                      />
                    </Stack>
                  )}
                  {activeStep === 3 && (
                    <Stack spacing={2}>
                      <FormRichTextField name="projectPlanSummary" />
                      <FormRichTextField name="investmentAppraisal" />
                      <FormRichTextField name="risks" />
                    </Stack>
                  )}
                  {activeStep === 4 && (
                    <Stack spacing={2}>
                      <FormRichTextField name="executiveSummary" />
                    </Stack>
                  )}
                </Box>
                <StepperNavigator
                  showPreviousButton={activeStep !== 0}
                  setActiveStep={setActiveStep}
                  onCancel={handleClose}
                  isDirty={isDirty}
                  submitButtonLabel={submitButtonLabel}
                />
              </form>
            </FormDataProvider>
          </CustomDialog>
        )}
        {!editBusinessCase && (
          <Stack spacing={2}>
            {Object.keys(businessCaseData).length === 0 ? (
              <Button
                color="secondary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setEditBusinessCase(true)}
              >
                {t("generic.button.add")}
              </Button>
            ) : (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                flex={1}
              >
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<EditOutlined />}
                  onClick={() => setEditBusinessCase(true)}
                >
                  {t("generic.button.edit")}
                </Button>
              </Stack>
            )}
            {businessCaseData.reasons && (
              <>
                <Typography variant="h2" fontWeight={900}>
                  {t(
                    `project.businessCase.addEditForm.steps.strategyAndObjectives`,
                  )}
                </Typography>
                <DisplayContent
                  area="businessCase.addEditForm.fields"
                  data={businessCaseData ?? {}}
                  fields={["reasons"]}
                />
              </>
            )}
            {(businessCaseData.doNothing ||
              businessCaseData.doMinimal ||
              businessCaseData.doSomething) && (
              <>
                <Typography variant="h2" fontWeight={900}>
                  {t(`project.businessCase.addEditForm.steps.options`)}
                </Typography>
                <DisplayContent
                  area="businessCase.addEditForm.fields"
                  data={businessCaseData ?? {}}
                  fields={["doNothing", "doMinimal", "doSomething"]}
                />
              </>
            )}
            {businessCaseData.benefits && (
              <>
                <Typography variant="h2" fontWeight={900}>
                  {t(`project.businessCase.addEditForm.steps.benefits`)}
                </Typography>
                <BenefitsTable
                  benefits={businessCaseData.benefits}
                  handleShowItem={handleShowItem}
                  handleShowEditItemForm={handleShowEditItemForm}
                  editItemIds={editItemIds}
                  showItemIds={showItemIds}
                  readOnly={true}
                />
              </>
            )}
            {(businessCaseData.projectPlanSummary ||
              businessCaseData.investmentAppraisal ||
              businessCaseData.risks) && (
              <>
                <Typography variant="h2" fontWeight={900}>
                  {t(`project.businessCase.addEditForm.steps.roi`)}
                </Typography>
                <DisplayContent
                  area="businessCase.addEditForm.fields"
                  data={businessCaseData ?? {}}
                  fields={[
                    "projectPlanSummary",
                    "investmentAppraisal",
                    "risks",
                  ]}
                />
              </>
            )}
            {businessCaseData.executiveSummary && (
              <>
                <Typography variant="h2" fontWeight={900}>
                  {t(`project.businessCase.addEditForm.steps.summary`)}
                </Typography>
                <DisplayContent
                  area="businessCase.addEditForm.fields"
                  data={businessCaseData ?? {}}
                  fields={["executiveSummary"]}
                />
              </>
            )}
          </Stack>
        )}
      </Section>
    </Page>
  );
};
