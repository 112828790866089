import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useT } from "../../hooks/useT";
import { useParams } from "react-router-dom";
import { project } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { Section } from "../../layout/Section";
import {
  StepButton,
  Stepper,
  Step,
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { CustomAccordion } from "../../layout/CustomAccordion";
import { FormTextField } from "../../base/form/mui/FormTextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { v4 as uuidV4 } from "uuid";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import { TitleWithHelper } from "../../base/TitleWithHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";

export const ProjectDefinitionForm = ({ onClose, isOpen }) => {
  const { t } = useT();
  const { id = "" } = useParams();
  const {
    isLoading,
    data: projectData,
    refetch: refetchProject,
  } = useQ(`project-${id}`, () => project.single({ id }));
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "background",
    "objectives",
    "scope",
    "constraints",
    "stakeholder",
  ];

  const schema = yup.object().shape({
    background: yup.string().trim().optional(),
    timing: yup.string().trim().optional(),
    bigPicture: yup.string().trim().optional(),
    benefitsDescription: yup.string().trim().optional(),
    objectivesSummary: yup.array().optional(),
    objectives: yup.string().trim().optional(),
    projectScope: yup.array().optional(),
    scopeExclusions: yup.string().trim().optional(),
    outcomes: yup.string().trim().optional(),
    constraints: yup.string().trim().optional(),
    assumptions: yup.string().trim().optional(),
    stakeholderDescription: yup.string().trim().optional(),
    interfaces: yup.string().trim().optional(),
  });

  const defaultObjectivesSummary = useMemo(() => {
    return [
      {
        id: "time",
        name: t("generic.status.time"),
      },
      {
        id: "scope",
        name: t("generic.status.scope"),
      },
      {
        id: "cost",
        name: t("generic.status.cost"),
      },
      {
        id: "quality",
        name: t("generic.status.quality"),
      },
      {
        id: "risk",
        name: t("generic.status.risk"),
      },
    ];
  }, []);

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      background: projectData?.background ?? "",
      timing: projectData?.timing ?? "",
      bigPicture: projectData?.bigPicture ?? "",
      benefitsDescription: projectData?.benefitsDescription ?? "",
      objectives: projectData?.objectives ?? "",
      objectivesSummary:
        (projectData?.objectivesSummary ?? []).length === 0
          ? defaultObjectivesSummary
          : projectData?.objectivesSummary ?? [],
      projectScope: projectData?.projectScope ?? [],
      scopeExclusions: projectData?.scopeExclusions ?? "",
      outcomes: projectData?.outcomes ?? "",
      constraints: projectData?.constraints ?? "",
      assumptions: projectData?.assumptions ?? "",
      stakeholderDescription: projectData?.stakeholderDescription ?? "",
      interfaces: projectData?.interfaces ?? "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = formData;

  const [objectivesSummary, projectScope] = useWatch({
    control,
    name: ["objectivesSummary", "projectScope"],
  });
  const objectivesSummaryRef = useRef(objectivesSummary);
  objectivesSummaryRef.current = objectivesSummary;
  const projectScopeRef = useRef(projectScope);
  projectScopeRef.current = projectScope;

  useEffect(() => {
    reset({
      background: projectData?.background ?? "",
      timing: projectData?.timing ?? "",
      bigPicture: projectData?.bigPicture ?? "",
      benefitsDescription: projectData?.benefitsDescription ?? "",
      objectives: projectData?.objectives ?? "",
      objectivesSummary:
        (projectData?.objectivesSummary ?? []).length === 0
          ? defaultObjectivesSummary
          : projectData?.objectivesSummary ?? [],
      projectScope: projectData?.projectScope ?? [],
      scopeExclusions: projectData?.scopeExclusions ?? "",
      outcomes: projectData?.outcomes ?? "",
      constraints: projectData?.constraints ?? "",
      assumptions: projectData?.assumptions ?? "",
      stakeholderDescription: projectData?.stakeholderDescription ?? "",
      interfaces: projectData?.interfaces ?? "",
    });
    if (isDirty) {
      reset({
        background: projectData?.background ?? "",
        timing: projectData?.timing ?? "",
        bigPicture: projectData?.bigPicture ?? "",
        benefitsDescription: projectData?.benefitsDescription ?? "",
        objectives: projectData?.objectives ?? "",
        objectivesSummary: objectivesSummary ?? [],
        projectScope: projectData?.projectScope ?? [],
        scopeExclusions: projectData?.scopeExclusions ?? "",
        outcomes: projectData?.outcomes ?? "",
        constraints: projectData?.constraints ?? "",
        assumptions: projectData?.assumptions ?? "",
        stakeholderDescription: projectData?.stakeholderDescription ?? "",
        interfaces: projectData?.interfaces ?? "",
      });
    }
  }, [reset, projectData, defaultObjectivesSummary, setValue]);

  const handleDropObjectivesSummaries = useCallback(
    ({ source, target }) => {
      const startingIndex = source.index;
      const endingIndex = target.index;
      const newObjectives = [...objectivesSummaryRef.current];
      const [removed] = newObjectives.splice(startingIndex, 1);
      newObjectives.splice(endingIndex, 0, removed);
      setValue("objectivesSummary", newObjectives, { shouldDirty: true });
    },
    [setValue],
  );

  const handleDropProjectScope = useCallback(
    ({ source, target }) => {
      const startingIndex = source.index;
      const endingIndex = target.index;
      if (startingIndex > projectScopeRef.current.length - 1) return;
      if (endingIndex > projectScopeRef.current.length - 1) return;
      const newProjectScope = [...projectScopeRef.current];
      const [removed] = newProjectScope.splice(startingIndex, 1);
      newProjectScope.splice(endingIndex, 0, removed);
      setValue("projectScope", newProjectScope, { shouldDirty: true });
    },
    [setValue],
  );

  const addNewProjectScope = useCallback(() => {
    setValue(
      "projectScope",
      [...projectScopeRef.current, { id: uuidV4(), text: "" }],
      { shouldDirty: true },
    );
  }, [setValue]);

  const deleteProjectScope = useCallback(
    (index) => {
      const newProjectScope = [...projectScopeRef.current];
      newProjectScope.splice(index, 1);
      setValue("projectScope", newProjectScope, { shouldDirty: true });
    },
    [setValue],
  );

  const isDeleteEnabled = useCallback(
    (index) =>
      !!(projectData?.projectScope ?? []).find(
        (s) => s.id === projectScopeRef.current[index].id,
      )?.items?.length,
    [projectData, projectScopeRef],
  );

  const toast = useToast();

  const updateProjectDefinition = useMutation(project.updateDefinition, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project definiton updated successfully");
      refetchProject();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating project definition");
    },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (isDirty) {
          updateProjectDefinition.mutate({
            id,
            data,
          });
        }
        if (activeStep !== 4) {
          setActiveStep((s) => s + 1);
        }
      }),
    [handleSubmit, id, isDirty],
  );

  const submitButtonLabel = useMemo(() => {
    if (isDirty || activeStep !== 4) {
      if (isDirty) {
        return activeStep !== 4
          ? t("generic.button.updateAndNext")
          : t("projectForm.submit.updateDefinition");
      }
      return t("generic.stepper.next");
    }
    return false;
  }, [isDirty, activeStep]);

  useConfirmLeavingPage(isDirty);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      scrollDependencyArray={[activeStep]}
    >
      <Section
        title={t("projectForm.editProject.title") + ": " + projectData?.name}
      >
        <FormDataProvider
          {...formData}
          formKey="projectForm.editProject.fields"
        >
          <form onSubmit={onSubmit}>
            <Stack spacing={3}>
              <StepperSections
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                formatLabel={(step) => t(`projectForm.steps.${step}.title`)}
              />

              {activeStep === 0 && (
                <>
                  <FormRichTextField name="background" />
                  <FormRichTextField name="timing" />
                  <FormRichTextField name="bigPicture" />
                </>
              )}
              {activeStep === 1 && (
                <>
                  <FormRichTextField name="benefitsDescription" />
                  <Box>
                    <TitleWithHelper
                      title={t(
                        "projectForm.editProject.fields.objectivesSummary.title",
                      )}
                      help={t(
                        "projectForm.editProject.fields.objectivesSummary.help",
                      )}
                    />
                    <CustomAccordion
                      tableView
                      draggable
                      droppable
                      onDrop={handleDropObjectivesSummaries}
                      header={{
                        expanded: false,
                        columns: [
                          {
                            id: "name",
                            title: t(
                              "projectForm.editProject.fields.objectivesSummary.tableHeader.pri",
                            ),
                            width: "50px",
                          },
                          {
                            id: "performance",
                            title: t(
                              "projectForm.editProject.fields.objectivesSummary.tableHeader.performance",
                            ),
                            flex: 2,
                          },
                          {
                            id: "description",
                            title: t(
                              "projectForm.editProject.fields.objectivesSummary.tableHeader.description",
                            ),
                            flex: 5,
                          },
                          {
                            id: "tolerance",
                            title: t(
                              "projectForm.editProject.fields.objectivesSummary.tableHeader.tolerance",
                            ),
                            flex: 4,
                          },
                        ],
                      }}
                      rows={(objectivesSummary ?? []).map((item, index) => ({
                        id: item.name,
                        expanded: false,
                        columns: [
                          {
                            content: (
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ ml: -1 }}
                              >
                                <MoreVertIcon />
                                <Typography sx={{ mr: 1 }}>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            ),
                            width: "50px",
                          },
                          {
                            title: !t(`generic.status.${item.id}`).includes(
                              "generic.status.",
                            )
                              ? t(`generic.status.${item.id}`)
                              : item.name,
                            flex: 2,
                          },
                          {
                            content: (
                              <FormTextField
                                name={`objectivesSummary[${index}].description`}
                                multiline
                                maxRows={4}
                                fieldKey="objectivesSummary.description"
                              />
                            ),
                            flex: 5,
                          },
                          {
                            content: (
                              <FormTextField
                                name={`objectivesSummary[${index}].tolerance`}
                                multiline
                                maxRows={4}
                                fieldKey="objectivesSummary.tolerance"
                              />
                            ),
                            flex: 4,
                          },
                        ],
                      }))}
                    />
                  </Box>
                  <FormRichTextField name="objectives" />
                </>
              )}
              {activeStep === 2 && (
                <>
                  <Box>
                    <TitleWithHelper
                      title={t(
                        "projectForm.editProject.fields.projectScope.title",
                      )}
                      help={t(
                        "projectForm.editProject.fields.projectScope.help",
                      )}
                    />
                    <CustomAccordion
                      tableView
                      draggable
                      droppable
                      onDrop={handleDropProjectScope}
                      header={{
                        expanded: false,
                        columns: [
                          {
                            title: t(
                              "projectForm.editProject.fields.projectScope.tableHeader",
                            ),
                            flex: 1,
                          },
                        ],
                      }}
                      rows={[
                        ...(projectScope ?? []).map((item, index) => ({
                          id: item.id,
                          expanded: false,
                          columns: [
                            {
                              content: (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ ml: -1 }}
                                >
                                  <MoreVertIcon />
                                  <Typography sx={{ mr: 1 }}>
                                    {index + 1}
                                  </Typography>
                                </Stack>
                              ),
                              width: "50px",
                            },
                            {
                              content: (
                                <FormTextField
                                  name={`projectScope[${index}].text`}
                                  fieldKey="projectScope.text"
                                />
                              ),
                              flex: 1,
                            },
                            {
                              content: (
                                <IconButton
                                  disabled={isDeleteEnabled(index)}
                                  onClick={() => {
                                    deleteProjectScope(index);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ),
                              width: 40,
                            },
                          ],
                        })),
                        {
                          id: "new",
                          expanded: false,
                          columns: [
                            {
                              content: (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ ml: -1 }}
                                >
                                  <MoreVertIcon />
                                  <Typography sx={{ mr: 1 }}>
                                    {(projectScope ?? []).length + 1}
                                  </Typography>
                                </Stack>
                              ),
                              width: "50px",
                            },
                            {
                              content: (
                                <Button
                                  startIcon={<AddCircleOutlineOutlinedIcon />}
                                  onClick={addNewProjectScope}
                                >
                                  {t(
                                    "projectForm.editProject.fields.projectScope.add",
                                  )}
                                </Button>
                              ),
                              flex: 1,
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                  <FormRichTextField name="scopeExclusions" />
                  <FormRichTextField name="outcomes" />
                </>
              )}
              {activeStep === 3 && (
                <>
                  <FormRichTextField name="constraints" />
                  <FormRichTextField name="assumptions" />
                </>
              )}
              {activeStep === 4 && (
                <>
                  <FormRichTextField name="stakeholderDescription" />
                  <FormRichTextField name="interfaces" />
                </>
              )}
            </Stack>
            <StepperNavigator
              showPreviousButton={activeStep !== 0}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </form>
        </FormDataProvider>
      </Section>
    </CustomDialog>
  );
};
